import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./DeleteModal.css";
import { toast } from "react-toastify";
import { PiSealWarning } from "react-icons/pi";
import WebService from "../../../Services/WebService";
import { useSelector } from "react-redux";
import { FiInfo } from "react-icons/fi";
interface PropData {
  show: boolean;
  action: string;
  onDelete: any;
  type: any;
  deleteMethod?: any;
  connectorType?: any;
}

const DeleteModal = (props: PropData) => {
  const access_token = useSelector((state: any) => state.aT.aT);
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const [showDelete, setShowDelete] = useState(false);
  const [showLinkedinMessagePopup, setShowLinkedinMessagePopup] = useState(false);

  useEffect(() => {
    setShowDelete(props.show);
  });
  const handleDelete = () => {
    WebService.addLoader("save11");
    setShowLinkedinMessagePopup(false);
    if (props.deleteMethod === "PUT") {
      WebService.putAPI({
        action: props.action,
        body: null,
        access_token: access_token,
        t_id: t_id
      }).then((res: any) => {
        if (res.message && props.connectorType === "LINKEDIN") {
          setShowLinkedinMessagePopup(true);
          WebService.removeLoader("save11")
          setShowDelete(false);
          props.onDelete(true);
        } else {
          toast(res.message ?? "Deleted successfully!", {
            position: "top-right",
            type: "success",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          WebService.removeLoader("save11")
          setShowDelete(false);
          props.onDelete(true);
        }
      });
    } else {
      WebService.deleteAPI({
        action: props.action,
        body: null,
        access_token: access_token,
        t_id: t_id
      }).then((res: any) => {
        if (res.message && props.connectorType === "LINKEDIN") {
          setShowLinkedinMessagePopup(true);
          WebService.removeLoader("save11")
          setShowDelete(false);
          props.onDelete(true);
        } else {
          toast(res.message ?? "Deleted successfully!", {
            position: "top-right",
            type: "success",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          WebService.removeLoader("save11")
          setShowDelete(false);
          props.onDelete(true);
        }

      });
    }
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    props.onDelete(false);
  };
  return showDelete ? (
    <Modal show={showDelete} onHide={handleCloseDelete} centered>
      <Modal.Header
        closeButton
        onClick={() => handleCloseDelete}
        className=" border-0 pb-0"
      ></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h2 className="text-danger">
            <PiSealWarning size={70} />
          </h2>
          <h4 className="font-18">
            {props.type === "DISCONNECT"
              ? "Are you sure you want to disconnect"
              : props.type === "CANCEL" ?
                "Are you sure you want to cancel subscription"
                : "Are you sure you want to delete"}
            ?
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className=" justify-content-center border-0 pt-0">
        <Button className="btn-brand-light" onClick={handleCloseDelete} >
          {props.type === "DISCONNECT" ? "No" : props.type === "CANCEL" ? "No" : "Cancel"}
        </Button>
        <Button className="btn-brand-1" onClick={handleDelete} id="save11">
          {props.type === "DISCONNECT" ? "Yes" : props.type === "CANCEL" ? "Yes" : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : showLinkedinMessagePopup ? (
    <Modal show={showLinkedinMessagePopup} onHide={() => setShowLinkedinMessagePopup(false)} centered>
      <Modal.Header
        closeButton
        onClick={() => setShowLinkedinMessagePopup(false)}
        className="border-0 pb-0"
      ></Modal.Header>
      <Modal.Body>
        <div className="">
          <h2 className="text-info text-center">
            <FiInfo size={70} />
          </h2>
          <div className="">
            <h4 className="font-16">
              <strong>To completely disconnect:</strong>
              <br />
              <ol type="1" className="mt-3">
                <li>Go to LinkedIn’s <strong>Privacy Settings</strong> page.</li>
                <li>Navigate to the <strong>Third Parties</strong> section.</li>
                <li>Find and remove <strong>AI CONNECTOR</strong>  from the list of connected apps or services.</li>
              </ol>
              This will ensure full disconnection from your LinkedIn account.
              {" "}
            </h4>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0 pt-0">
        <Button className="btn-brand-light" onClick={() => setShowLinkedinMessagePopup(false)} >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    null
  )
};

export default DeleteModal;







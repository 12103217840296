import {
	Button,
	FloatingLabel,
	Form,
	Offcanvas,
	Col,
	Row,
} from "react-bootstrap";
import { BsBookmarkFill } from "react-icons/bs";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import AIDatePicker from "../Common/AIDatePicker/AIDatePicker";
import HelperService from "../../Services/HelperService";
import moment from "moment";
import { useSelector } from "react-redux";
import WMDateRangePicker from "../Common/WMDateRangePicker/WMDateRangePicker";
import AsyncSelect from "react-select/async";
interface propsData {
	sendData?: any;
	parentCallback?: any;
	fromSavedFilter?: boolean;
	filterId?: any;
	isFromReviewAll?: any;
	isFromEscalated?: any;
	filterName?: any;
}

const EmailFilterBlade = (props: propsData) => {
	const t_id = useSelector((state: any) => state.tI.tenantId);
	const role = useSelector((state: any) => state.uR.role);
	const access_token = useSelector((state: any) => state.aT.aT);
	const [showFilterNameBlade, setShowFilterNameBlade] = useState(false);
	const [error, setErrors] = useState<any>("");
	const [filterNameErr, setFilterNameErr] = useState<any>("");
	const [filterName, setFilterName] = useState<any>(props.filterName);
	const [startDateFlag, setStartDateFlag] = useState(false);
	const [endDateFlag, setEndDateFlag] = useState(false);
	const [startDate, setStartDate] = useState<any>(
		props?.parentCallback?.startDate !== undefined
			? new Date(props?.parentCallback?.startDate)
			: new Date()
	);
	const [endDate, setEndDate] = useState<any>(
		props?.parentCallback?.endDate !== undefined
			? new Date(props?.parentCallback?.endDate)
			: new Date()
	);
	const [keywords, setKeywords] = useState<any>(
		props?.parentCallback?.keywords
	);
	const [percentage, setPercentage] = useState<any>(
		props?.parentCallback?.percentage
	);

	const [senders, setSenders] = useState<any>(props?.parentCallback?.sender);
	const [receivers, setReceivers] = useState<any>(props?.parentCallback?.recipient);
	const [checkbox, setCheckbox] = useState({
		AllEmails: props.parentCallback?.allMails ?? props.parentCallback?.markedAsFlag,
		FlaggedEmails: props.parentCallback?.flaggedmails ?? props.parentCallback?.markedAsFlag,
		ReviewedEmails: props.parentCallback?.reviewedMails ?? props.parentCallback?.markedAsView,
		flaggedNotReviewed: props.parentCallback?.flaggedNotReviewed ?? props.parentCallback?.flaggedNotReviewed,
		escalateAndSend: props.parentCallback?.escalateAndSend ?? props.parentCallback?.escalatAndSend,
		notReviewed: props.parentCallback?.notReviewed ?? props.parentCallback?.notReviewed,
	});
	const [switchValues, setSwitchValues] = useState({
		SubLine: props.parentCallback?.subjectLine || undefined,
		Body: props.parentCallback?.body || undefined,
		Attachment: props.parentCallback?.attachment || undefined,
		Disclaimers: props.parentCallback?.disclaimers || undefined,
	});
	const [fromSavedFilter, setFromSavedFilter] = useState<Boolean>(props?.fromSavedFilter ?? false);
	const [date_range, setdateRange] = useState<any>({
		startDate: props?.parentCallback?.startDate !== undefined
			? new Date(props?.parentCallback?.startDate)
			: new Date(),
		endDate: props?.parentCallback?.endDate !== undefined
			? new Date(props?.parentCallback?.endDate)
			: new Date(),
		key: "selection",
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
		register,
		setError,
		watch
	} = useForm();
	var watchVariable = watch();

	useEffect(() => {
		if (fromSavedFilter) {
			setValue("sampleReview", props.parentCallback.percentage ? "PERCENTAGE" : "COUNT")
			setValue("percentage", props.parentCallback.percentage ?? props.parentCallback.sampleCount)
			let groupList = props.parentCallback.groupList && props.parentCallback.groupList?.length > 0 ? props.parentCallback.groupList.map((group: any) => ({
				value: group.id,
				label: `${group.group_name}`,
				category: "group",
			})) : [];
			let userList = props.parentCallback.userList && props.parentCallback.userList?.length > 0 ? props.parentCallback.userList.map((user: any) => ({
				value: user.id,
				label: `${user.first_name} ${user.last_name}`,
				category: "user"
			})) : [];
			setGroupOptions([...groupList]);
			setUserOptions([...userList]);
			setSelectedOptions([...groupList, ...userList]);

		} else {
			setValue("sampleReview", props?.parentCallback?.sampleCount ? "COUNT" : "PERCENTAGE");
			setValue("percentage", props?.parentCallback?.sampleCount ? props?.parentCallback?.sampleCount : props?.parentCallback?.percentage ? props?.parentCallback?.percentage : 100);
		}

	}, [props.parentCallback]);

	const handleCheckboxChange = (key: string, value: boolean) => {
		if (key === "ReviewedEmails" && value) {
			setCheckbox((prev) => ({
				...prev,
				ReviewedEmails: true,
				notReviewed: false,
			}));
		} else if (key === "notReviewed" && value) {
			setCheckbox((prev) => ({
				...prev,
				ReviewedEmails: false,
				notReviewed: true,
			}));
		} else {
			setCheckbox((prev) => ({
				...prev,
				[key]: value,
			}));
		}
	};


	const handleSendersChange = (e: any) => {
		setErrors("");
		const values = e.target.value.split(",").map((value: any) => value.trim());
		setSenders(values);
	};
	const handleReceiversChange = (e: any) => {
		setErrors("");
		const values = e.target.value.split(",").map((value: any) => value.trim());
		setReceivers(values);
	};

	const handleFilterApply = () => {
		if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
			setError("percentage", {
				type: "custom",
				message: "Please enter a valid number between 1 and 100",
			});
			return false;
		}
		if (error !== "") {
			return false;
		}
		if (moment(startDate).isAfter(endDate, 'day')) {
			setEndDateFlag(true);
			return;
		} else {
			setEndDateFlag(false);
		}

		let userArr: any = [];
		let grpArr: any = [];
		selectedOptions.forEach((reviewer: any) => {
			if (reviewer.category === "user") {
				userArr.push(reviewer);
			} else {
				grpArr.push(reviewer);
			}
		});

		var obj = {
			sender: senders?.length === 1 ? senders[0] !== "" ? senders : undefined : senders,
			recipient: receivers?.length === 1 ? receivers[0] !== "" ? receivers : undefined : receivers,
			startDate: date_range.startDate,
			endDate: date_range.endDate,
			keywords: keywords ?? "",
			percentage: (props.isFromReviewAll || props.isFromEscalated) ? undefined : watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
			sampleCount: (props.isFromReviewAll || props.isFromEscalated) ? undefined : watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
			allMails: checkbox?.AllEmails,
			flaggedmails: checkbox?.FlaggedEmails,
			reviewedMails: checkbox?.ReviewedEmails,
			escalateAndSend: checkbox?.escalateAndSend,
			notReviewed: checkbox?.notReviewed,
			flaggedNotReviewed: checkbox?.flaggedNotReviewed,
			subjectLine: true,
			body: switchValues?.Body,
			attachment: switchValues?.Attachment,
			disclaimers: switchValues?.Disclaimers,
			userList: userArr,
			groupList: grpArr,
		};

		props.sendData(obj, "FILTER");
	};

	const handleSaveFilter = () => {
		if (fromSavedFilter && !filterName) {
			setFilterNameErr("Please enter filter name");
			return false;
		}
		if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
			setError("percentage", {
				type: "custom",
				message: "Please enter a valid number between 1 and 100",
			});
			return false;
		}
		if (!fromSavedFilter) {
			setShowFilterNameBlade(true);
		} else {
			handleSaveFilterName();
		}

	};

	const resetFilter = () => {
		props.sendData({});
		setSenders([]);
		setReceivers([]);

		setCheckbox({
			AllEmails: undefined,
			FlaggedEmails: undefined,
			ReviewedEmails: undefined,
			flaggedNotReviewed: undefined,
			escalateAndSend: undefined,
			notReviewed: undefined,
		});
		setdateRange({
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		})
	};

	const handleSaveFilterName = () => {
		setFilterNameErr("");
		if (!filterName && !props.filterName) {
			setFilterNameErr("Please enter filter name");
			return false;
		}
		let userArr: any = [];
		let grpArr: any = [];
		selectedOptions.forEach((reviewer: any) => {
			if (reviewer.category === "user") {
				userArr.push(reviewer);
			} else {
				grpArr.push(reviewer);
			}
		});
		var filter = {
			sender: senders,
			recipient: receivers,
			startDate: HelperService.convertDateToISO(date_range.startDate),
			endDate: HelperService.convertDateToISO(date_range.endDate),
			keywords: keywords ?? "",
			percentage: (props.isFromReviewAll || props.isFromEscalated) ? undefined : watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
			sampleCount: (props.isFromReviewAll || props.isFromEscalated) ? undefined : watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
			allMails: checkbox?.AllEmails,
			flaggedmails: checkbox?.FlaggedEmails,
			reviewedMails: checkbox?.ReviewedEmails,
			flaggedNotReviewed: checkbox?.flaggedNotReviewed,
			escalateAndSend: checkbox?.escalateAndSend,
			notReviewed: checkbox?.notReviewed,
			subjectLine: switchValues?.SubLine,
			body: switchValues?.Body,
			attachment: switchValues?.Attachment,
			disclaimers: switchValues?.Disclaimers,
		}

		if (props.filterId) {
			WebService.addLoader("apply1");
			return WebService.putAPI({
				action: `filter/update?id=${props.filterId}`,
				body: {
					"filter": {
						...filter,
						"userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
						"groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
					},
					"name": filterName,
					"type": "EMAIL"
				},
				isShowError: true,
				access_token: access_token,
				t_id: t_id
			})
				.then((res: any) => {
					toast.success(res?.message);
					WebService.removeLoader("apply1");
					setFromSavedFilter(false);
					setShowFilterNameBlade(false);
					props.sendData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
				})
				.catch((error: any) => {
					WebService.removeLoader("apply1");
					return error;
				});
		} else {
			WebService.addLoader("apply");
			return WebService.postAPI({
				action: `filter/save`,
				body: {
					"filter": {
						...filter,
						"userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
						"groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
					},
					"name": filterName,
					"type": "EMAIL"
				},
				isShowError: true,
				access_token: access_token,
				t_id: t_id
			})
				.then((res: any) => {
					toast.success(res?.message);
					WebService.removeLoader("apply");
					props.sendData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
					setShowFilterNameBlade(false);
				})
				.catch((error: any) => {
					WebService.removeLoader("apply");
					return error;
				});
		}
	};
	const dateChange = (date_range: any) => {
		setdateRange(date_range);
	};


	const [userOptions, setUserOptions] = useState<any>(props.parentCallback.userList);
	const [groupOptions, setGroupOptions] = useState<any>(props.parentCallback.groupList);
	const [selectedOptions, setSelectedOptions] = useState<any>(
		!props.filterId && (props.parentCallback.userList?.length > 0 || props.parentCallback.groupList?.length > 0)
			? [...(props.parentCallback.userList || []), ...(props.parentCallback.groupList || [])]
			: []
	);

	const fetchUsers = async (inputValue: any) => {
		try {
			var userResponse: any = [];
			userResponse = await WebService.getAPI({
				action: `users/list?page=1&keyword=${inputValue}`,
				body: {},
				access_token: access_token,
				t_id: t_id
			});
			return userResponse?.list?.map((user: any) => ({
				value: user.id,
				label: user.first_name + " " + user.last_name,
				category: "user",
			}));
		} catch (error) {
			console.error("Error fetching users:", error);
			return [];
		}
	};
	const fetchGroups = async (inputValue: any) => {
		try {
			var groupResponse: any = [];
			groupResponse = await WebService.getAPI({
				action: `group-list?page=1&keyword=${inputValue}`,
				body: {},
				access_token: access_token,
				t_id: t_id
			});
			return groupResponse?.list?.map((group: any) => ({
				value: group.id,
				label: group.group_name,
				category: "group",
			}));
		} catch (error) {
			console.error("Error fetching groups:", error);
			return [];
		}
	}

	const loadOptions = async (inputValue: any, callback: any) => {
		try {
			const [userOptions, groupOptions] = await Promise.all([
				fetchUsers(inputValue),
				fetchGroups(inputValue),
			]);
			setUserOptions(userOptions);
			setGroupOptions(groupOptions);

			const applyTo = [
				userOptions?.length > 0 &&
				{ value: "all_users", label: (<label className="text-secondary font-14" >Select All Users</label>), category: "user" },
				...userOptions,
				groupOptions?.length > 0 &&
				{ value: "all_groups", label: (<label className="text-secondary font-14" >Select All Groups</label>), category: "group" },
				...groupOptions,
			];
			return applyTo?.filter((ele: any) => ele);
		} catch (error) {
			return [];
		}
	};

	const handleChange = (selected: any) => {
		if (!selected) {
			setSelectedOptions([]);
			return;
		}

		const isAllUsersSelected = selected.some(
			(option: any) => option.value === "all_users"
		);
		const isAllGroupsSelected = selected.some(
			(option: any) => option.value === "all_groups"
		);

		if (isAllUsersSelected) {
			setSelectedOptions([
				...userOptions.filter((option: any) => option.value !== "all_users"),
				...selected.filter((option: any) => option.category !== "user"),
			]);
		} else if (isAllGroupsSelected) {
			setSelectedOptions([
				...groupOptions.filter((option: any) => option.value !== "all_groups"),
				...selected.filter((option: any) => option.category !== "group"),
			]);
		} else {
			setSelectedOptions(selected);
		}
	};


	return (
		<>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Filters</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body >
				<div className="form-style">
					{
						fromSavedFilter &&
						<div className="mt-1 mb-3">
							<FloatingLabel
								controlId="filtername"
								label="Filter Name"
								className="mb-3"
							>
								<Form.Control
									type="text"
									onChange={(e: any) => { setFilterNameErr(""); setFilterName(e.target.value) }}
									placeholder="Filter Name"
									defaultValue={filterName}
								/>
								<span style={{ display: filterNameErr ? "block" : "none" }} className="px-1 text-danger font-12">{filterNameErr}</span>
							</FloatingLabel>
						</div>
					}

					<div className="mt-1 mb-3">
						<FloatingLabel
							controlId="senders"
							label="Senders"
							className="mb-3"
						>
							<Form.Control
								type="text"
								onChange={handleSendersChange}
								placeholder="Senders"
								value={senders}
							/>
						</FloatingLabel>
					</div>
					<div className="mt-3 mb-3">
						<FloatingLabel
							controlId="receiver"
							label="Receivers"
							className="mb-3"
						>
							<Form.Control
								type="text"
								onChange={handleReceiversChange}
								placeholder="Receivers"
								value={receivers}
							/>
						</FloatingLabel>
					</div>
					<div className="">
						<WMDateRangePicker
							selectData={(date_range: any) => {
								dateChange(date_range);
							}}
							date_range={date_range}
						>
						</WMDateRangePicker>
					</div>
					<div className="">
						<div className="mt-1 mb-3">
							<FloatingLabel
								controlId="Keywords"
								label="Keywords"
								className="mb-3"
							>
								<Form.Control
									type="text"
									onChange={(e: any) => {
										setErrors("");
										setKeywords(e.target.value);
									}}
									placeholder="Keywords"
									value={keywords}
								/>
							</FloatingLabel>
						</div>
					</div>
					{
						props.isFromReviewAll || props.isFromEscalated ?
							"" :
							<div>
								<div className="mb-3">
									<div className="d-flex gap-1">
										<Col lg={6}>
											<FloatingLabel controlId="reviewSample" label="Review sample" className="">
												<Form.Select
													aria-label="Select"
													{...register("sampleReview", {
														required: "Please select review sample type",
													})}
													onChange={(e: any) => { clearErrors("percentage"); setValue("sampleReview", e.target.value) }}
												>
													<option
														value=""
														disabled={true}
													>
														Select type
													</option>
													<option selected value="PERCENTAGE">Percentage</option>
													<option value="COUNT">Count</option>
												</Form.Select>
											</FloatingLabel>
											{errors.sampleReview && (
												<Label
													title={errors.sampleReview.message?.toString()}
													modeError={true}
													showStar={true}
													type=""
												/>
											)}
										</Col>

										<Col lg={6}>
											<FloatingLabel controlId="ReviewPercentage" label="Sample Quantity" className="">
												<Form.Control
													type="text"
													{...register("percentage", {
														required: "Please enter value",
														validate: (value) => {
															if (watchVariable.sampleReview === "PERCENTAGE") {
																if (value === "") return "Please enter value";
																if (!/^[1-9]\d?$|^100$/.test(value)) {
																	return "Please enter a valid number between 1 and 100";
																}
															}
															return true;
														},
													})}
													defaultValue={100}
													onChange={(e: any) => {
														const enteredValue = e.target.value;
														if (watchVariable.sampleReview === "PERCENTAGE" && parseInt(enteredValue) > 100) {
															setError("percentage", {
																type: "custom",
																message: "Please enter a valid number between 1 and 100",
															});
															setValue("percentage", enteredValue);
														} else {
															clearErrors("percentage");
															setValue("percentage", enteredValue);
														}
													}}
												/>
												{errors.percentage && (
													<Label
														title={errors.percentage.message?.toString()}
														modeError={true}
														showStar={true}
														type=""
													/>
												)}
											</FloatingLabel>
										</Col>
									</div>
								</div>
							</div>
					}
					<div>
						<AsyncSelect
							isMulti
							cacheOptions
							loadOptions={loadOptions}
							defaultOptions
							onChange={handleChange}
							placeholder="Select Users or Groups"
							value={selectedOptions}
							className="scroll-verticle"
						/>
					</div>
					<hr className="border-secondary-subtle w-100" />
					<div className="px-3 mb-2">
						<div className="mb-1">
							<Row className="row-cols-4 mb-2 d-flex gap-2" >
								<div className="px-2 py-1 bg-danger-subtle">
									<Form.Check
										type="checkbox"
										name="emailType"
										id="FlaggedEmails"
										className="labe-text-dark checkbox-danger"
										label="Flagged"
										checked={checkbox.FlaggedEmails}
										onChange={(event) =>
											handleCheckboxChange("FlaggedEmails", event?.target?.checked)
										}
									/>
								</div>
								<div className="px-2 py-1 bg-success-subtle">
									<Form.Check
										type="checkbox"
										name="emailType"
										className="labe-text-dark checkbox-success"
										id="ReviewedEmails"
										label="Reviewed"
										checked={checkbox.ReviewedEmails}
										onChange={(event) =>
											handleCheckboxChange("ReviewedEmails", event?.target?.checked)
										}
									/>
								</div>
								<div className="px-2 py-1 bg-warning-subtle">
									<Form.Check
										type="checkbox"
										name="emailType"
										id="escalateAndSendEmails"
										className="labe-text-dark checkbox-warning"
										label="Escalate"
										checked={checkbox.escalateAndSend}
										onChange={(event) =>
											handleCheckboxChange("escalateAndSend", event?.target?.checked)
										}
									/>
								</div>
							</Row>
							<Row className="mb-2 d-flex gap-2">
								<div className="px-2 py-1 bg-primary-subtle col-6 text-nowrap">
									<Form.Check
										type="checkbox"
										name="emailType"
										className="labe-text-dark checkbox-primary "
										id="ReviewedEmails2"
										label="Flagged but not reviewed"
										checked={checkbox.flaggedNotReviewed}
										onChange={(event) =>
											handleCheckboxChange("flaggedNotReviewed", event?.target?.checked)
										}
									/>
								</div>
								<div className="px-2 py-1 bg-secondary-subtle col-4 text-nowrap">
									<Form.Check
										type="checkbox"
										name="emailType"
										className="labe-text-dark checkbox-secondary"
										id="notReviewedEmails"
										label="Not yet reviewed"
										checked={checkbox.notReviewed}
										onChange={(event) =>
											handleCheckboxChange("notReviewed", event?.target?.checked)
										}
									/>
								</div>
							</Row>

						</div>
					</div>
				</div>
				<hr className="border-secondary-subtle" />
				<div className=" form-style">
					<div className="mt-3">
						<span className="text-danger font-12">{error}</span>
						<div className="mt-3 d-flex gap-3">
							{
								role !== "BASIC_USER" ?
									<Button
										id="apply1"
										type="button"
										className="btn btn-brand-light w-100"
										onClick={() => handleSaveFilter()}
									>
										<BsBookmarkFill className="icon" /> Save Filter
									</Button>
									: ""

							}
							{!props.fromSavedFilter && (
								<Button
									type="submit"
									id="apply-btn"
									className="btn btn-brand-1 w-100"
									onClick={() => {
										handleFilterApply()
									}
									}>
									Apply
								</Button>
							)}
						</div>
						{!props.fromSavedFilter && (
							<Col
								className="item-align-end font-12 mt-2"
								style={{ textAlign: "end" }}
							>
								<a
									onClick={() => resetFilter()}
									className="font-14 cursor-pointer"
								>
									Reset Filter
								</a>
							</Col>
						)}
					</div>
				</div>
			</Offcanvas.Body >

			<Offcanvas
				show={showFilterNameBlade}
				onHide={() => setShowFilterNameBlade(false)}
				placement="end"
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Filter Name</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="px-3 py-3">
					<div className=" form-style">
						<div className="mt-3">
							<div className="mt-3">
								<FloatingLabel
									controlId="filterName"
									label="Filter Name"
									className=""
								>
									<Form.Control
										type="text"
										onChange={(e: any) => {
											setFilterNameErr("");
											setFilterName(e.target.value);
										}}
										placeholder="Filter Name"
									/>
								</FloatingLabel>
							</div>
							<span className="text-danger font-12">{filterNameErr}</span>
							<div className="mt-3 mb-2">
								<Button
									type="button"
									className="btn btn-brand-1 w-100"
									id={props.filterId ? "apply1" : "apply"}
									onClick={() => handleSaveFilterName()}
								>
									Save
								</Button>
							</div>
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>

		</>
	);
};
export default EmailFilterBlade;







import { Button, FloatingLabel, Form, Offcanvas, Col, Row } from "react-bootstrap";
import { PiDownloadSimple } from "react-icons/pi";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import WebService from "../../Services/WebService";
import { useEffect, useRef, useState } from "react";
import { Label } from "../Common/Label/Label";
import AIDatePicker from "../Common/AIDatePicker/AIDatePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import WMDateRangePicker from "../Common/WMDateRangePicker/WMDateRangePicker";
import { BsBookmarkFill } from "react-icons/bs";
import { toast } from "react-toastify";
interface propsData {
  sendData: any;
  parentCallback: any;
  isFromReviewAll?: any;
  isFromEscalated?: any
  filterId?: any;
  fromSavedFilter?: any;
  filterName?: any;
  selectedTab?: any;
}
const SMSFilterBlade = (props: propsData) => {
  const role = useSelector((state: any) => state.uR.role);
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const mobileNoRef = useRef<any>(null);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    register,
    clearErrors,
    setError,
    reset
  } = useForm();
  var watchVariable = watch();
  const [selectedMobile, setSelectedMobile] = useState<any[]>(props.parentCallback?.accounts);
  const [startDate, setStartDate] = useState<any>(props?.parentCallback?.startDate !== undefined
    ? props?.parentCallback?.startDate
    : new Date());
  const [endDate, setEndDate] = useState<any>(props?.parentCallback?.endDate !== undefined
    ? props?.parentCallback?.endDate
    : new Date());
  const [keywords, setKeywords] = useState<any>(props.parentCallback?.keywords);
  const [notes, setNotes] = useState<any>(props.parentCallback?.notes);
  const [reviews, setReviews] = useState<any[]>([]);
  const [startDateFlag, setStartDateFlag] = useState(false);
  const [endDateFlag, setEndDateFlag] = useState(false);
  const [checkbox, setCheckbox] = useState({
    markedAsFlag: props.parentCallback?.markedAsFlag || undefined,
    markedAsView: props.parentCallback?.markedAsView || undefined,
    escalateAndSend: props.parentCallback?.escalateAndSend || undefined,
    flaggedNotReviewed: props.parentCallback?.flaggedNotReviewed || undefined,
    notReviewed: props.parentCallback?.notReviewed || undefined,
  });
  // const [error, setError] = useState<any>("");
  const [senders, setSenders] = useState<any>(props?.parentCallback?.sender);
  const [receivers, setReceivers] = useState<any>(
    props?.parentCallback?.recipient
  );
  const [percentage, setPercentage] = useState<any>(
    props?.parentCallback?.percentage
  );
  const [date_range, setdateRange] = useState<any>({
    startDate: props?.parentCallback?.startDate !== undefined
      ? props?.parentCallback?.startDate
      : new Date(),
    endDate: props?.parentCallback?.endDate !== undefined
      ? props?.parentCallback?.endDate
      : new Date(),
    key: "selection",
  });

  const handleCheckboxChange = (key: string, value: boolean) => {
    if (key === "markedAsView" && value) {
      setCheckbox((prev) => ({
        ...prev,
        markedAsView: true,
        notReviewed: false,
      }));
    } else if (key === "notReviewed" && value) {
      setCheckbox((prev) => ({
        ...prev,
        markedAsView: false,
        notReviewed: true,
      }));
    } else {
      setCheckbox((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const mobileNumberSelection = (selectedOption: any) => {
    var arr: any = [];
    if (selectedOption?.length > 0) {
      selectedOption.forEach((element: any) => {
        arr.push(element);
      });
    }
    setSelectedMobile(arr);

  };
  const mobileNumberPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `sms/accounts`,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            var updatedOptions = res.map((mobile: any) => ({
              id: mobile.id,
              label: mobile.refId,
              value: mobile.id
            }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const handleFilterApply = () => {
    // if (error !== "") {
    //   return false;
    // }
    if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
      setError("percentage", {
        type: "custom",
        message: "Please enter a valid number between 1 and 100",
      });
      return false;
    }
    if (moment(startDate).isAfter(endDate, 'day')) {
      setEndDateFlag(true);
      return;
    } else {
      setEndDateFlag(false);
    }

    let userArr: any = [];
    let grpArr: any = [];
    selectedOptions.forEach((reviewer: any) => {
      if (reviewer.category === "user") {
        userArr.push(reviewer);
      } else {
        grpArr.push(reviewer);
      }
    });

    var obj = {
      sender:
        senders?.length === 1
          ? senders[0] !== ""
            ? senders
            : undefined
          : senders,
      recipient:
        receivers?.length === 1
          ? receivers[0] !== ""
            ? receivers
            : undefined
          : receivers,
      accounts: selectedMobile,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
      keywords: keywords,
      percentage: watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
      sampleCount: watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
      notes: notes,
      markedAsFlag: checkbox?.markedAsFlag,
      markedAsView: checkbox?.markedAsView,
      escalateAndSend: checkbox?.escalateAndSend,
      flaggedNotReviewed: checkbox?.flaggedNotReviewed,
      notReviewed: checkbox?.notReviewed,
      reviewId: undefined,
      userList: userArr,
      groupList: grpArr,
    };
    props.sendData(obj, "FILTER");

  };
  const handleError = (error: any) => {

  };
  const handleReviewSelection = (type: any, status: any) => {
    if (reviews.includes(type)) {
      const newArray = reviews.filter((item) => item !== type);
      setReviews(newArray);
    } else {
      const newArray = [...reviews, type];
      setReviews(newArray);
    }
  };

  const resetFilter = () => {
    props.sendData({})
    mobileNoRef?.current?.clearValue();
    setStartDate("");
    setEndDate("");
    setKeywords("");
    setNotes("");
    setCheckbox({
      markedAsFlag: undefined,
      markedAsView: undefined,
      escalateAndSend: undefined,
      flaggedNotReviewed: undefined,
      notReviewed: undefined
    });
    setdateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    })
  };

  const handleSendersChange = (e: any) => {
    // setError("");
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setSenders(values);
  };
  const handleReceiversChange = (e: any) => {
    // setError("");
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setReceivers(values);
  };

  const dateChange = (date_range: any) => {
    setdateRange(date_range);
  };

  useEffect(() => {
    if (fromSavedFilter) {
      setValue("sampleReview", props.parentCallback.percentage ? "PERCENTAGE" : "COUNT")
      setValue("percentage", props.parentCallback.percentage ?? props.parentCallback.sampleCount)
      let groupList = props.parentCallback.groupList && props.parentCallback.groupList?.length > 0 ? props.parentCallback.groupList.map((group: any) => ({
        value: group.id,
        label: `${group.group_name}`,
        category: "group",
      })) : [];
      let userList = props.parentCallback.userList && props.parentCallback.userList?.length > 0 ? props.parentCallback.userList.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
        category: "user"
      })) : [];
      setGroupOptions([...groupList]);
      setUserOptions([...userList]);
      setSelectedOptions([...groupList, ...userList]);

    } else {
      setValue("sampleReview", props?.parentCallback?.sampleCount ? "COUNT" : "PERCENTAGE");
      setValue("percentage", props?.parentCallback?.sampleCount ? props?.parentCallback?.sampleCount : props?.parentCallback?.percentage ? props?.parentCallback?.percentage : 100);
    }

  }, [props.parentCallback]);

  const [userOptions, setUserOptions] = useState<any>(props.parentCallback.userList);
  const [groupOptions, setGroupOptions] = useState<any>(props.parentCallback.groupList);
  const [selectedOptions, setSelectedOptions] = useState<any>(
    !props.filterId && (props.parentCallback.userList?.length > 0 || props.parentCallback.groupList?.length > 0)
      ? [...(props.parentCallback.userList || []), ...(props.parentCallback.groupList || [])]
      : []
  );

  const [fromSavedFilter, setFromSavedFilter] = useState<Boolean>(props?.fromSavedFilter ?? false);
  const [showFilterNameBlade, setShowFilterNameBlade] = useState(false);
  const [filterNameErr, setFilterNameErr] = useState<any>("");
  const [filterName, setFilterName] = useState<any>(props.filterName);

  const fetchUsers = async (inputValue: any) => {
    try {
      var userResponse: any = [];
      userResponse = await WebService.getAPI({
        action: `users/list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return userResponse?.list?.map((user: any) => ({
        value: user.id,
        label: user.first_name + " " + user.last_name,
        category: "user",
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  const fetchGroups = async (inputValue: any) => {
    try {
      var groupResponse: any = [];
      groupResponse = await WebService.getAPI({
        action: `group-list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return groupResponse?.list?.map((group: any) => ({
        value: group.id,
        label: group.group_name,
        category: "group",
      }));
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  }

  const loadOptions = async (inputValue: any, callback: any) => {
    try {
      const [userOptions, groupOptions] = await Promise.all([
        fetchUsers(inputValue),
        fetchGroups(inputValue),
      ]);
      setUserOptions(userOptions);
      setGroupOptions(groupOptions);

      const applyTo = [
        userOptions?.length > 0 &&
        { value: "all_users", label: (<label className="text-secondary font-14" >Select All Users</label>), category: "user" },
        ...userOptions,
        groupOptions?.length > 0 &&
        { value: "all_groups", label: (<label className="text-secondary font-14" >Select All Groups</label>), category: "group" },
        ...groupOptions,
      ];
      return applyTo?.filter((ele: any) => ele);
    } catch (error) {
      return [];
    }
  };

  const handleChange = (selected: any) => {
    if (!selected) {
      setSelectedOptions([]);
      return;
    }

    const isAllUsersSelected = selected.some(
      (option: any) => option.value === "all_users"
    );
    const isAllGroupsSelected = selected.some(
      (option: any) => option.value === "all_groups"
    );

    if (isAllUsersSelected) {
      setSelectedOptions([
        ...userOptions.filter((option: any) => option.value !== "all_users"),
        ...selected.filter((option: any) => option.category !== "user"),
      ]);
    } else if (isAllGroupsSelected) {
      setSelectedOptions([
        ...groupOptions.filter((option: any) => option.value !== "all_groups"),
        ...selected.filter((option: any) => option.category !== "group"),
      ]);
    } else {
      setSelectedOptions(selected);
    }
  };

  const handleSaveFilter = () => {
    if (fromSavedFilter && !filterName) {
      setFilterNameErr("Please enter filter name");
      return false;
    }
    if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
      setError("percentage", {
        type: "custom",
        message: "Please enter a valid number between 1 and 100",
      });
      return false;
    }

    if (!fromSavedFilter) {
      setShowFilterNameBlade(true);
    } else {
      handleSaveFilterWithName();
    }

  };

  const handleSaveFilterWithName = () => {
    setFilterNameErr("");
    if (!filterName && !props.filterName) {
      setFilterNameErr("Please enter filter name");
      return false;
    }
    let userArr: any = [];
    let grpArr: any = [];
    selectedOptions.forEach((reviewer: any) => {
      if (reviewer.category === "user") {
        userArr.push(reviewer);
      } else {
        grpArr.push(reviewer);
      }
    });
    var filter = {
      sender:
        senders?.length === 1
          ? senders[0] !== ""
            ? senders
            : undefined
          : senders,
      recipient:
        receivers?.length === 1
          ? receivers[0] !== ""
            ? receivers
            : undefined
          : receivers,
      accounts: selectedMobile,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
      keywords: keywords,
      percentage: watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
      sampleCount: watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
      notes: notes,
      markedAsFlag: checkbox?.markedAsFlag,
      markedAsView: checkbox?.markedAsView,
      escalateAndSend: checkbox?.escalateAndSend,
      flaggedNotReviewed: checkbox?.flaggedNotReviewed,
      notReviewed: checkbox?.notReviewed,
      reviewId: undefined,
      userList: userArr,
      groupList: grpArr,
    }

    if (props.filterId) {
      WebService.addLoader("apply1");
      return WebService.putAPI({
        action: `filter/update?id=${props.filterId}`,
        body: {
          "filter": {
            ...filter,
            "userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
            "groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
          },
          "name": filterName,
          "type": props.selectedTab
        },
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          toast.success(res?.message);
          WebService.removeLoader("apply1");
          setFromSavedFilter(false);
          setShowFilterNameBlade(false);
          props.sendData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
        })
        .catch((error: any) => {
          WebService.removeLoader("apply1");
          return error;
        });
    } else {
      WebService.addLoader("apply");
      return WebService.postAPI({
        action: `filter/save`,
        body: {
          "filter": {
            ...filter,
            "userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
            "groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
          },
          "name": filterName,
          "type": props.selectedTab
        },
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          toast.success(res?.message);
          WebService.removeLoader("apply");
          props.sendData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
          setShowFilterNameBlade(false);
        })
        .catch((error: any) => {
          WebService.removeLoader("apply");
          return error;
        });
    }
  };


  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleFilterApply, handleError)}
      >
        <Offcanvas.Body className="px-0 form-style ">
          <div className="px-3 mt-3">
            {
              fromSavedFilter &&
              <div className="mt-1 mb-3">
                <FloatingLabel
                  controlId="filtername"
                  label="Filter Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    onChange={(e: any) => { setFilterNameErr(""); setFilterName(e.target.value) }}
                    placeholder="Filter Name"
                    defaultValue={filterName}
                  />
                  <span style={{ display: filterNameErr ? "block" : "none" }} className="px-1 text-danger font-12">{filterNameErr}</span>
                </FloatingLabel>
              </div>
            }
            <div className="mt-1 mb-3">
              <FloatingLabel
                controlId="senders"
                label="Senders"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={handleSendersChange}
                  placeholder="Senders"
                  value={senders}
                />
              </FloatingLabel>
            </div>
            <div className="mt-3 mb-3">
              <FloatingLabel
                controlId="receiver"
                label="Receivers"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={handleReceiversChange}
                  placeholder="Receivers"
                  value={receivers}
                />
              </FloatingLabel>
            </div>

            <WMDateRangePicker
              selectData={(date_range: any) => {
                dateChange(date_range);
              }}
              date_range={date_range}
            >
            </WMDateRangePicker>

            <div className="mb-3">
              <FloatingLabel
                controlId="Keywords"
                label="Keywords"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={(e: any) => {
                    setKeywords(e.target.value);
                  }}
                  placeholder="Keywords"
                  value={keywords}
                />
              </FloatingLabel>
            </div>

            <Row>
              <div className="mb-3">
                <div className="d-flex gap-1">
                  <Col lg={6}>
                    <FloatingLabel controlId="reviewSample" label="Sample Quantity" className="">
                      <Form.Select
                        aria-label="Select"
                        {...register("sampleReview", {
                          required: "Please select review sample type",
                        })}
                        onChange={(e: any) => { clearErrors("percentage"); setValue("sampleReview", e.target.value) }}
                      >
                        <option
                          value=""
                          disabled={true}
                        >
                          Select type
                        </option>
                        <option selected value="PERCENTAGE">Percentage</option>
                        <option value="COUNT">Count</option>
                      </Form.Select>
                    </FloatingLabel>
                    {errors.sampleReview && (
                      <Label
                        title={errors.sampleReview.message?.toString()}
                        modeError={true}
                        showStar={true}
                        type=""
                      />
                    )}
                  </Col>

                  <Col lg={6}>
                    <FloatingLabel controlId="ReviewPercentage" label="Sample Count" className="">
                      <Form.Control
                        type="text"
                        {...register("percentage", {
                          required: "Please enter value",
                          validate: (value) => {
                            if (watchVariable.sampleReview === "PERCENTAGE") {
                              if (value === "") return "Please enter value";
                              if (!/^[1-9]\d?$|^100$/.test(value)) {
                                return "Please enter a valid number between 1 and 100";
                              }
                            }
                            return true;
                          },
                        })}
                        defaultValue={10}
                        onChange={(e: any) => {
                          const enteredValue = e.target.value;
                          if (watchVariable.sampleReview === "PERCENTAGE" && parseInt(enteredValue) > 100) {
                            setError("percentage", {
                              type: "custom",
                              message: "Please enter a valid number between 1 and 100",
                            });
                            setValue("percentage", enteredValue);
                          } else {
                            clearErrors("percentage");
                            setValue("percentage", enteredValue);
                          }
                        }}
                      />
                      {errors.percentage && (
                        <Label
                          title={errors.percentage.message?.toString()}
                          modeError={true}
                          showStar={true}
                          type=""
                        />
                      )}
                    </FloatingLabel>
                  </Col>
                </div>
              </div>
            </Row>

          </div>

          <div className="mb-3 px-3">
            <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={handleChange}
              placeholder="Select Users or Groups"
              value={selectedOptions}
              className="scroll-verticle"
            />
          </div>

          <div className="px-4 mb-2">
            <div className="mb-1 text-nowrap">
              <Row className="row-cols-4 mb-2 d-flex gap-2" >
                <div className="px-2 py-1 bg-danger-subtle">
                  <Form.Check
                    type="checkbox"
                    id="markedAsFlagSMS"
                    label="Flagged"
                    className="labe-text-dark checkbox-danger"
                    checked={checkbox.markedAsFlag}
                    onChange={(event) => handleCheckboxChange('markedAsFlag', event?.target?.checked)}
                  />
                </div>
                <div className="px-2 py-1 bg-success-subtle">
                  <Form.Check
                    type="checkbox"
                    id="markedAsViewSMS"
                    label="Reviewed"
                    className="labe-text-dark checkbox-success"
                    checked={checkbox.markedAsView}
                    onChange={(event) => handleCheckboxChange('markedAsView', event?.target?.checked)}
                  />
                </div>
                <div className="px-2 py-1 bg-warning-subtle">
                  <Form.Check
                    type="checkbox"
                    id="escalateAndSendSMS"
                    label="Escalated"
                    className="labe-text-dark checkbox-warning"
                    checked={checkbox.escalateAndSend}
                    onChange={(event) => handleCheckboxChange('escalateAndSend', event?.target?.checked)}
                  />
                </div>
              </Row>

              <Row className="mb-2 d-flex gap-2">
                <div className="px-2 py-1 bg-primary-subtle col-6 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-primary"
                    id="FlaggedReviewedSMS"
                    label="Flagged but not reviewed"
                    checked={checkbox.flaggedNotReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("flaggedNotReviewed", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-secondary-subtle col-4 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-secondary"
                    id="NotYetReviewedSMS"
                    label="Not yet reviewed"
                    checked={checkbox.notReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("notReviewed", event?.target?.checked)
                    }
                  />
                </div>
              </Row>
            </div>
          </div>
          <div className=" form-style">
            <div className="px-3">
              <div className="mt-3 d-flex gap-3">
                {
                  role !== "BASIC_USER" ?
                    <Button
                      id="apply1"
                      type="button"
                      className="btn btn-brand-light w-100"
                      onClick={() => handleSaveFilter()}
                    >
                      <BsBookmarkFill className="icon" /> Save Filter
                    </Button>
                    : ""

                }
                {!props.fromSavedFilter && (
                  <Button
                    type="submit"
                    id="apply-btn"
                    className="btn btn-brand-1 w-100"
                    onClick={() => {
                      handleFilterApply()
                    }
                    }>
                    Apply
                  </Button>
                )}
              </div>
              {!props.fromSavedFilter &&
                <Col className="item-align-end font-12 mt-2" style={{ "textAlign": "end" }}>
                  <a
                    onClick={() => resetFilter()}
                    className="font-14 cursor-pointer"
                  >
                    Reset Filter
                  </a>
                </Col>
              }
            </div>
          </div>
        </Offcanvas.Body>
      </Form>

      <Offcanvas
        show={showFilterNameBlade}
        onHide={() => setShowFilterNameBlade(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Name</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-3 py-3">
          <div className=" form-style">
            <div className="mt-3">
              <div className="mt-3">
                <FloatingLabel
                  controlId="filterName"
                  label="Filter Name"
                  className=""
                >
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      setFilterNameErr("");
                      setFilterName(e.target.value);
                    }}
                    placeholder="Filter Name"
                  />
                </FloatingLabel>
              </div>
              <span className="text-danger font-12">{filterNameErr}</span>
              <div className="mt-3 mb-2">
                <Button
                  type="button"
                  className="btn btn-brand-1 w-100"
                  id={props.filterId ? "apply1" : "apply"}
                  onClick={() => handleSaveFilterWithName()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default SMSFilterBlade;

import { Badge, Button, Card, CloseButton, Col, Dropdown, Form, Offcanvas, Row, Table } from "react-bootstrap";
import { RxReload } from "react-icons/rx";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { HiMiniFlag } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import NoDataFound from "../../assets/images/empty_item.svg";
import TableLoader from "../Common/TableLoader/TableLoader";
import AIPagination from "../Common/Pagination/AIPagination";
import { CgClose } from "react-icons/cg";
import HelperService from "../../Services/HelperService";
import BloombergViewBlade from "./BloombergViewBlade";
import { TbCloudUpload } from "react-icons/tb";
import { toast } from "react-toastify";
import { BsFunnel } from "react-icons/bs";
import BloombergConversationBlade from "./BloombergConversationBlade";
import { useSelector } from "react-redux";
import ReviewNamePopup from "../EmailReview/ReviewNamePopup";
import { FaFileCsv, FaFilePdf, FaRegFileLines } from "react-icons/fa6";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";



interface propsData {
  viewObject?: any;
  changedObject?: any;
  keyWord?: any;
  excalated?: any;
  filterObject?: any;
  callBack?: any;
  tab?: any;
  sendData?: any;
  automark?: any;
  isActive?: any;
  changeIsActive?: any
  reviewTab?: any
  reviewedCount?: any;
  totalCount?: any;
  reviewAllFlag?: any;
  bloombergFilterBlade?: any;
  changeInList?: any;
  filterTab?: any;
  sendBulkReviewList?: any;
  bulkExport?: any;
  clearFilterCallback?: any;
  isReviewAll?: any;
  isReviewed?: any;
  reviewType?: any;
  sampleReviewCount?: any;
  exportReviewId?: any;
  exportFormat?: any;
  filterFlag?: any;
  loader?: any;
}

var isFatchCallRunning = false;
let showTab = false;

const BloombergTab = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdDate");
  const [orderBy, setOrderBy] = useState("DESC");
  const [clickable, setClickable] = useState("dateTime");
  const [isActive, setIsActive] = useState(false);
  const [automark, setAutoMark] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [excalated, setEscalated] = useState(false);
  const [deleteExclatedPostList, setDeleteExcalatedPost] = useState<any[]>([]);
  const [bloombergList, setBloombergList] = useState<any[]>([]);
  const [id, setId] = useState<any>();
  const [reviewedCount, setReviewedCount] = useState(0);
  const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
  const [bulkReviewList, setBulkReviewList] = useState<any[]>([]);
  const [allSelect, setAllSelect] = useState(false);
  const [allSelectForBulk, setallSelectForBulk] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isConversation, setIsConversation] = useState(false);
  const [bloombergConversationData, setBloombergConversationData] = useState<any>();
  const [replyId, setReplyId] = useState<any>("");
  const [chatId, setChatId] = useState<any>("");
  const [showReviewNamePopup, setShowReviewNamePopup] = useState<any>(false);
  const [reviewId, setReviewId] = useState<any>("");
  const [oldReviewId, setOldReviewId] = useState<any>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  useEffect(() => {
    var data1 = atob(localStorage.getItem("ucd") ?? "");
    if (role === "COMPLIANCE_OFFICER") {
      if (data1 === "" || data1 === undefined) {
        navigate("/access-denied");
      } else {
        var json = JSON.parse(data1);
        setAutoMark(json.reviewLogs);
        setComplainceUserLogAccess(json.reviewLogs);
      }
    }
  }, []);


  useEffect(() => {
    if (props.excalated) {
      setEscalated(() => true);
    }
    if (props.changeInList && props.changeInList.idList?.length > 0) {
      var updatedBloombergList = [...bloombergList];
      props.changeInList.idList.forEach((id: any) => {
        const index = updatedBloombergList.findIndex(
          (bloombergItem: any) => bloombergItem.id === id
        );

        if (index !== -1) {
          let reviewClass = "";
          if (updatedBloombergList[index].markedAsFlag && !props.changeInList.markedAsView) {
            reviewClass = "flag";
          }
          if (props.changeInList.markedAsView) {
            reviewClass = reviewClass + " " + "mardk-as-viewed";
          }
          if (updatedBloombergList[index].escalateAndSend && !props.changeInList.markedAsView) {
            reviewClass = reviewClass + " " + "escalate-send";
          }
          updatedBloombergList[index].markedAsFlag =
            props.changeInList.markedAsFlag;
          updatedBloombergList[index].markedAsView =
            props.changeInList.markedAsView;
          updatedBloombergList[index].escalateAndSend =
            props.changeInList.escalateAndSend;
          updatedBloombergList[index].reviewClass = reviewClass;
          updatedBloombergList[index].bulkReview = false;
          updatedBloombergList[index].notes = props.changeInList.notes;
          updatedBloombergList[index].check = false;
          updatedBloombergList[index].bulkReview = false;
        }
      });

      toggleViewSidebar(false);
      setBulkReviewList([]);
      setAllSelect(false);
      setallSelectForBulk(false);
      setDeleteExcalatedPost([]);
    } else if (props.changeInList && props.changeInList.idList?.length > 0) {
      getBloombergList(page, reviewId);
    } else {
      if (JSON.stringify(props.filterObject) != '{}' && reviewId !== undefined && reviewId !== "") {
        getBloombergList(page, reviewId);
        toggleViewSidebar(false);
      } else {
        setPage(1)
        getBloombergList(1);
      }
    }
  }, [
    sortBy,
    orderBy,
    props.changeInList,
  ]);

  useEffect(() => {
    getBloombergList(page, reviewId);
  }, [page]);

  useEffect(() => {
    if ((props.keyWord !== "" && props.keyWord !== undefined) && !props.isReviewAll && !props.excalated) {
      setOldReviewId(reviewId);
    } else {
      if (JSON.stringify(props.filterObject) !== '{}' && !props.isReviewAll && !props.excalated) {
        setOldReviewId(reviewId);
      } else {
        getBloombergList(page);
      }
    }
  }, [props.keyWord]);

  useEffect(() => {
    if (JSON.stringify(props.filterObject) !== '{}' && !props.isReviewAll && !props.excalated) {
      setOldReviewId(reviewId);
    } else {
      getBloombergList(page);
    }
  }, [props.filterObject]);

  useEffect(() => {
    if (oldReviewId !== undefined) {
      getReviewId();
    }
  }, [oldReviewId]);


  useEffect(() => {
    setAutoMark(props.automark);
  }, [props.automark]);


  const toggleViewSidebar = (isAtc: boolean) => {
    if (props.reviewTab !== "BLOOMBERG") {
      props.changeIsActive(false, props.reviewTab);
    }
    setTimeout(() => {
      props.changeIsActive(isAtc, "BLOOMBERG");
      setIsActive(isAtc);
    }, 5);
  };

  useEffect(() => {
    if (!props.isActive) {
      setIsActive(props.isActive);
    }
  }, [props.isActive]);



  const getReviewId = () => {
    if (!props.isReviewAll && !props.excalated) {
      let filterObject = {
        ...props.filterObject,
        groupList: props.filterObject.groupList && props.filterObject.groupList.length > 0 ? props.filterObject.groupList.map((group: any) => group.value) : [],
        userList: props.filterObject.userList && props.filterObject.userList.length > 0 ? props.filterObject.userList.map((user: any) => user.value) : [],
      }

      const obj1 = {
        page: "1",
        sort_by: sortBy,
        order_by: orderBy,
        keyword: props.keyWord ?? "",
        escalated: props.excalated ?? false,
      };
      setLoader(true);
      var queryParam = new URLSearchParams(obj1);
      return WebService.postAPI({
        action: `bloomberg/headers?` + queryParam,
        body: props.filterObject.percentage === undefined || props.filterObject.sampleCount === undefined ? { ...filterObject, "percentage": 100, "oldReviewId": oldReviewId } : { ...filterObject, "oldReviewId": oldReviewId },
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          setReviewId(res.list[0].review.uuid);
          setPage(1);
          getBloombergList(1, res.list[0].review.uuid);
        })
        .catch((error: any) => {
          setLoader(false);
          return error;
        });
    } else {
      setReviewId(props.filterObject.reviewId);
    }
  };

  const getBloombergList = (pg: any, reviewId?: any, isRefresh?: any) => {
    if (isFatchCallRunning) {
      return false;
    }
    props.loader(1);
    isFatchCallRunning = true;
    showTab = false;
    const obj1 = {
      page: pg.toString(),
      sort_by: sortBy,
      order_by: orderBy,
      keyword: props.keyWord ?? "",
      escalated: props.excalated ?? false,
    };

    let filterObject = {
      ...props.filterObject,
      groupList: props.filterObject.groupList && props.filterObject.groupList.length > 0 ? props.filterObject.groupList.map((group: any) => group.value) : [],
      userList: props.filterObject.userList && props.filterObject.userList.length > 0 ? props.filterObject.userList.map((user: any) => user.value) : [],
    }

    setLoader(true);
    var queryParam = new URLSearchParams(obj1);
    return WebService.postAPI({
      action: `bloomberg/headers?` + queryParam,
      body: (reviewId !== undefined && reviewId !== "" && !props.excalated && !props.isReviewAll) ? { "reviewId": reviewId } : filterObject,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedList = res.list.map((post: any) => {
            let reviewClass;
            if (post.markedAsFlag) {
              reviewClass = "flag";
            }
            if (post.markedAsView) {
              reviewClass = reviewClass + " " + "mardk-as-viewed";
            }
            if (post.escalateAndSend) {
              reviewClass = reviewClass + " " + "escalate-send";
            }
            return {
              ...post,
              check: false,
              reviewClass: reviewClass,
              bulkReview: false,
            };

          })
          if (props.reviewAllFlag && props.reviewType !== "BLOOMBERG") {
            showTab = true;
          }
          setReviewedCount(res.reviewedCount);
          setTotalCount(res.count);
          setBloombergList(updatedList);
          setAllSelect(false);
          setIsConnected(res.is_connected);
          setallSelectForBulk(false);
          props.totalCount(res.count);
          props.reviewedCount(res.reviewedCount);
        } else {
          if (props.reviewAllFlag && props.reviewType !== "ALL") {
            showTab = true;
          }
          setBloombergList(() => []);
          setLoader(false);
          setTotalCount(res.count);
          setReviewedCount(res.reviewedCount);
          setIsConnected(res.is_connected);
          props.totalCount(res.count);
          props.reviewedCount(res.reviewedCount);
        }
        if (selectedIndex != null) {
          toggleViewSidebar(true);
          setSelectedIndex(0);
          setSelectedPost(JSON.parse(JSON.stringify(res.list[0])));
        }
        isFatchCallRunning = false;
        setLoader(false);
        props.loader(0);
      })
      .catch((error: any) => {
        props.loader(0);
        isFatchCallRunning = false;
        setLoader(false);
        return error;
      });
  };

  const handleShowFilterBlade = () => {
    props.bloombergFilterBlade(true);
  };

  const handleCheckboxChange = (clickedPost: any) => {
    setAllSelect(false)
    if (clickedPost.check === false) {
      var arr = [...deleteExclatedPostList];
      arr.push(clickedPost.id);
      setDeleteExcalatedPost(arr);
      props.bulkExport(arr);
    } else {
      var arr = deleteExclatedPostList.filter((id) => id !== clickedPost.id);
      setDeleteExcalatedPost(arr);
      props.bulkExport(arr);
    }
    setBloombergList((prevList) =>
      prevList.map((post) =>
        post.id === clickedPost.id ? { ...post, check: !post.check } : post
      )
    );
  };

  useEffect(() => {
    if (props.exportReviewId && props.exportFormat && bloombergList?.length > 0) {
      // handleExport(props.exportFormat);
    }
  }, [props.exportReviewId]);

  const handleExport = (format: any) => {
    delete props.filterObject.percentage
    toggleViewSidebar(false);
    WebService.addLoader("export-btn-bloomberg");
    return WebService.postAPI({
      action: `download/manager?type=BLOOMBERG&keyword=${props.keyWord}`,
      body: {
        ...props.filterObject,
        "userList": props.filterObject.userList && props.filterObject.userList.length > 0 ? props.filterObject.userList.map((user: any) => user.value) : [],
        "groupList": props.filterObject.groupList && props.filterObject.groupList.length > 0 ? props.filterObject.groupList.map((group: any) => group.value) : [],
        "reviewId": JSON.stringify(props.filterObject) === "{}" ? "" : props.reviewAllFlag ? props.filterObject.reviewId : reviewId,
        "fileType": format,
        "idList": props.excalated || props.reviewAllFlag ? deleteExclatedPostList : bulkReviewList,
        "exportRequestId": props.excalated || props.reviewAllFlag ? props.exportReviewId : undefined,
        "exportRequestType": props.excalated ? "ESCALATE" : props.reviewAllFlag ? "REVIEW" : undefined,
        "actual_count": props?.sampleReviewCount && props?.sampleReviewCount[0]?.reviewedCount ? props?.sampleReviewCount[0]?.reviewedCount : totalCount

      },
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        if (!props.exportReviewId) {
          toast.success(res.message);
        }
        WebService.removeLoader("export-btn-bloomberg");
      })
      .catch((error: any) => {
        WebService.removeLoader("export-btn-bloomberg");
        return error;
      });
  };

  const handleBulkReviewCheckboxChange = (clickedPost: any) => {
    if (clickedPost.bulkReview === false) {
      var arr = [...bulkReviewList];
      arr.push(clickedPost.id);
      setBulkReviewList(arr);

      if (bulkReviewList.length === bloombergList.length) {
        setallSelectForBulk(true);
      }
      if (bulkReviewList.length === bloombergList.length) {
        setAllSelect(true);
      }
    } else {
      var arr = bulkReviewList.filter((id) => id !== clickedPost.id);
      setBulkReviewList(arr);
      setallSelectForBulk(false);
    }

    props.sendBulkReviewList(arr);
    setBloombergList((prevList) =>
      prevList.map((post) =>
        post.id === clickedPost.id
          ? { ...post, bulkReview: !post.bulkReview }
          : post
      )
    );
  };

  const handleDeleteEscalated = () => {
    if (deleteExclatedPostList.length === 0) {
      toast.error("Please select at least one post")
      return false;
    }
    WebService.addLoader("remove-escalation-btn-bloomberg");
    var obj = {
      ids: deleteExclatedPostList,
    };
    return WebService.postAPI({
      action: `bloomberg/escalated`,
      body: obj,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        toast.success(res.message);
        getBloombergList(page);
        WebService.removeLoader("remove-escalation-btn-bloomberg");
      })
      .catch((error: any) => {
        WebService.removeLoader("remove-escalation-btn-bloomberg");
        return error;
      });
  };

  const handleAllSelectForBulk = (checkeStatus: any) => {
    const updatedList = bloombergList.map((post: any) => ({
      ...post,
      bulkReview: checkeStatus,
    }));

    const selectedIds = updatedList
      .filter((post: any) => post.bulkReview)
      .map((post: any) => post.id);
    setallSelectForBulk(checkeStatus);
    setBloombergList(updatedList);
    setBulkReviewList(selectedIds);
    props.sendBulkReviewList(selectedIds);

  };


  const handleAllSelect = (checkeStatus: any) => {
    const updatedList = bloombergList.map((post: any) => ({
      ...post,
      check: checkeStatus,
    }));

    const selectedIds = updatedList
      .filter((post: any) => post.check)
      .map((post: any) => post.id);

    setAllSelect(checkeStatus);
    setBloombergList(updatedList);
    setDeleteExcalatedPost(selectedIds);
    props.bulkExport(selectedIds);
  };

  const gotoConnectPage = () => {
    navigate("/connector");
  }

  const toggleConversationSlideBar = (data: any) => {
    setIsConversation(data);
  };

  const handleCallback = (data: any, post: any, id: any) => {
    if (data) {
      setIsConversation(data);
    }
    if (id) {
      setReplyId(id)
    }
    if (post) {
      setChatId(post.id);
    }
    setBloombergConversationData(post);
  };

  return (
    <>
      {
        !isConnected && !loader ?
          <div className="table-wrap position-relative">
            <div className="no-data-flound">
              <div className="text-center">
                <img src={NoDataFound} width={110} alt="noDataimage" className="mb-3" />
                <h3 className="text-secondary font-12">
                  <p>No Bloomberg channel is currently connected for archiving.</p>
                  <Button className="btn-brand-1" onClick={gotoConnectPage} >
                    + Connect now
                  </Button>
                </h3>
              </div>
            </div>
          </div>
          :

          (bloombergList.length === 0 && props.excalated) ||
            (bloombergList.length === 0 && props.reviewAllFlag) ? (
            props.filterObject !== null && props.filterFlag ?
              (
                <div className="collapse-page ">
                  <div className={`table-card overflow-hidden w-100 ${props.isActive ? "postTableWidth" : ""}`} >
                    <div className="w-100">
                      <Row className="align-items-center">
                        <Col className="d-flex gap-3">
                          <h3 className="table-heading text-nowrap">
                            Bloomberg
                            {(() => {
                              const reviewCount = props?.sampleReviewCount && props?.sampleReviewCount[0]?.reviewedCount ? props?.sampleReviewCount[0]?.reviewCount : 0;
                              const totalCount = props?.sampleReviewCount && props?.sampleReviewCount[0]?.totalCount ? props?.sampleReviewCount[0]?.totalCount : 0;

                              return !props.reviewAllFlag || (props.reviewAllFlag && !reviewCount && !totalCount)
                                ? `(${HelperService.abbrNum(totalCount, 2)})`
                                : `(${HelperService.abbrNum(reviewCount, 2)} out of ${HelperService.abbrNum(totalCount, 2)})`;
                            })()}

                          </h3>
                        </Col>
                        <Col className="text-end">
                          {props.excalated ? (
                            <Button
                              id="remove"
                              className="btn-brand-light me-3"
                              onClick={() => {
                                handleDeleteEscalated();
                                toggleViewSidebar(false);
                              }}
                            >
                              Remove from Escalation
                            </Button>
                          ) : (
                            ""
                          )}

                          {props.excalated || props.reviewAllFlag ? (
                            <Button
                              className="btn-brand-light text-nowrap"
                              onClick={handleShowFilterBlade}
                            >
                              <BsFunnel size={18} /> Filter{" "}
                            </Button>
                          ) : (
                            ""
                          )}
                          <Button
                            className="btn-brand-light me-3 ms-3"
                            onClick={() => {
                              setOrderBy("DESC");
                              setSortBy("created_time");
                              getBloombergList(page, JSON.stringify(props.filterObject) === "{}" ? undefined : reviewId, true);
                              toggleViewSidebar(false);
                              setAllSelect(false);
                              setallSelectForBulk(false);
                              setDeleteExcalatedPost([]);
                            }}
                          >
                            <RxReload size={16} />
                          </Button>
                        </Col>
                      </Row>
                      <Card className="table-container mt-3">
                        <div className="data-container ">
                          <div className="table-wrap position-relative">
                            <div className="no-data-flound bg-white py-5 rounded-4">
                              <div className="text-center">
                                <img alt="NoDateFound" src={NoDataFound} width={110} className="mb-3" />
                                <h3 className="text-secondary font-12">
                                  No data found
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
          )

            : (
              <div className="collapse-page">
                <div className={`table-card overflow-hidden w-100 ${props.isActive ? "postTableWidth" : ""}`}>
                  <div className="w-100">
                    <Row className="align-items-center">
                      <Col className="d-flex gap-3 justify-content-between align-items-center pb-3">
                        <h3 className="table-heading text-nowrap">
                          Bloomberg
                          {(() => {
                            const reviewCount = props.reviewAllFlag ? props?.sampleReviewCount[0]?.reviewedCount ?? props?.sampleReviewCount[0]?.reviewCount : 0;
                            const totalCount1 = props.reviewAllFlag ? props?.sampleReviewCount[0]?.totalCount : 0;

                            return !props.reviewAllFlag || (props.reviewAllFlag && !reviewCount && !totalCount1)
                              ? `(${HelperService.abbrNum(totalCount, 2)})`
                              : `(${HelperService.abbrNum(reviewCount, 2)} out of ${HelperService.abbrNum(totalCount1, 2)})`;
                          })()}

                        </h3>
                        {Object.keys(props.filterObject).length > 1 && !props.excalated && props.filterObject?.reviewId === undefined ?
                          <>
                            <div className="d-flex gap-1 flex-wrap align-items-center w-100">
                              {props.filterObject?.startDate && props.filterObject?.endDate
                                ?
                                <Badge
                                  bg="secondary">
                                  Date range: {HelperService.getFormatedDateForDetail(props.filterObject?.startDate, "MM-DD-YYYY")} - {HelperService.getFormatedDateForDetail(props.filterObject?.endDate, "MM-DD-YYYY")}
                                </Badge>
                                : ""}

                              {props.filterObject?.keywords
                                ? <Badge bg="secondary">Keyword: {props.filterObject?.keywords}
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Keyword", "")}
                                  /></Badge>
                                : ""}

                              {props.filterObject?.percentage || props.filterObject?.sampleCount
                                ? <Badge bg="secondary">Review Sample: {props.filterObject?.percentage ? props.filterObject?.percentage : props.filterObject?.sampleCount}
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => { props.clearFilterCallback(props.filterObject?.percentage ? "Percentage" : "sampleCount", "") }}
                                  /></Badge>
                                : ""}

                              {props.filterObject?.markedAsFlag
                                ? <Badge bg="secondary">Show Flagged Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Flagged", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.markedAsView
                                ? <Badge bg="secondary">Show Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Reviewed", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.escalateAndSend
                                ? <Badge bg="secondary">Show Escalated Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("Escalated", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.flaggedNotReviewed
                                ? <Badge bg="secondary">Show Flagged But Not Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("NotReviewed", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.notReviewed
                                ? <Badge bg="secondary">Show Not Yet Reviewed Messages
                                  <CloseButton
                                    variant="white"
                                    style={{ fontSize: "9px" }}
                                    onClick={() => props.clearFilterCallback("NotYetReviewed", "")}
                                  /></Badge>
                                : ""}
                              {props.filterObject?.groupList && props.filterObject?.groupList.map((item: any, index: any) => {
                                return (
                                  <Badge bg="secondary">{item?.label}
                                    <CloseButton
                                      variant="white"
                                      style={{ fontSize: "9px" }}
                                      onClick={() => props.clearFilterCallback("groupList", item.id + item.label)}
                                    />
                                  </Badge>
                                )
                              })
                              }

                              {props.filterObject?.userList && props.filterObject?.userList.map((item: any, index: any) => {
                                return (
                                  <Badge bg="secondary">{item?.label}
                                    <CloseButton
                                      variant="white"
                                      style={{ fontSize: "9px" }}
                                      onClick={() => props.clearFilterCallback("userList", item.id + item.label)}
                                    />
                                  </Badge>
                                )
                              })
                              }
                            </div>
                          </>
                          : ""}
                        <div className="d-flex gap-2">
                          {Object.keys(props.filterObject).length > 1 && !props.excalated && props.filterObject?.reviewId === undefined && JSON.stringify(props.filterObject) != '{}' ?
                            <div className="text-center d-flex gap-2 align-items-lg-center">
                              {
                                bloombergList?.length > 0 ?
                                  <Button
                                    className="btn-brand-light text-nowrap"
                                    onClick={() => setShowReviewNamePopup(true)}
                                  >
                                    Save Review
                                  </Button>
                                  : ""
                              }

                              <Button
                                className="btn-brand-light text-nowrap"
                                onClick={() => { props.clearFilterCallback("ALL", {}) }} >
                                Clear filter
                              </Button>
                            </div> : ""}

                          {!props.reviewAllFlag ? (
                            props.excalated ? (
                              <Button
                                id="remove-escalation-btn-bloomberg"
                                className="btn-brand-light"
                                onClick={() => {
                                  handleDeleteEscalated();
                                }}
                              >
                                Remove from Escalation
                              </Button>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {/* {
                            bloombergList.length > 0 && !props.excalated && !props.reviewAllFlag &&
                              role !== "BASIC_USER" ?
                              <Dropdown >
                                <Dropdown.Toggle
                                  className="btn-brand-light text-nowrap"
                                  id="export-btn-bloomberg"
                                >
                                  <TbCloudUpload size={18} className="me-1" /> Export
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="set-dropdown-width">
                                  <Dropdown.Item className="on-hover" id="PDF" onClick={() => { handleExport("PDF") }}>
                                    <FaFilePdf size={18} className="me-1" />
                                    PDF
                                  </Dropdown.Item>
                                  <Dropdown.Item className="on-hover" id="CSV" onClick={() => handleExport("CSV")}>
                                    <FaFileCsv size={18} className="me-1" />
                                    CSV
                                  </Dropdown.Item>
                                  <Dropdown.Item className="on-hover" id="EML" onClick={() => handleExport("EML")}>
                                    <FaRegFileLines size={18} className="me-1" />
                                    EML
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>
                              : ""
                          } */}

                          {props.excalated || props.reviewAllFlag ? (
                            <Button
                              className="btn-brand-light text-nowrap"
                              onClick={handleShowFilterBlade}
                            >
                              <BsFunnel size={18} /> Filter{" "}
                            </Button>
                          ) : (
                            ""
                          )}

                          <Button
                            className="btn-brand-light"
                            onClick={() => {
                              setOrderBy("DESC");
                              setSortBy("created_time");
                              getBloombergList(page, JSON.stringify(props.filterObject) === "{}" ? undefined : reviewId, true);
                              toggleViewSidebar(false);
                              setAllSelect(false);
                              setallSelectForBulk(false);
                              setDeleteExcalatedPost([]);
                            }}
                          >
                            <RxReload size={16} />
                          </Button>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {props.excalated ? (
                        <Col className="d-flex gap-3">
                          <h3 className="table-heading"></h3>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <Card className="table-container">
                      <Table className="table-style">
                        <thead>
                          <tr>
                            <th className="d-flex">
                              <div>
                                {complainceUserLogAccess && role !== "BASIC_USER" ? (
                                  props.excalated || props.reviewAllFlag ? (
                                    <Form.Check
                                      type="checkbox"
                                      id="selectAll"
                                      checked={allSelect}
                                      onClick={() => {
                                        handleAllSelect(!allSelect);
                                      }}
                                      label=""
                                    />
                                  ) : (
                                    <Form.Check
                                      type="checkbox"
                                      id="selectAll1"
                                      checked={allSelectForBulk}
                                      onClick={() => {
                                        handleAllSelectForBulk(!allSelectForBulk);
                                      }}
                                      label=""
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="cursor-pointer"
                                onClick={() => {
                                  setSortBy("last_message_sent_time");
                                  setClickable("last_message_sent_time");
                                  setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                                }}>
                                Date
                                {sortBy === "last_message_sent_time" && clickable === "last_message_sent_time" ? (
                                  orderBy === "DESC" ? (
                                    <MdOutlineKeyboardArrowDown
                                      className="sort-icon cursor-pointer"
                                      size={14}
                                    />
                                  ) : (
                                    <MdOutlineKeyboardArrowUp
                                      className="sort-icon cursor-pointer"
                                      size={14}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>

                            <th
                              className="cursor-pointer"
                              onClick={() => {
                                setSortBy("conversation_id");
                                setClickable("conversation_id");
                                setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                              }}
                            >
                              Conversation Id
                              {sortBy === "conversation_id" && clickable === "conversation_id" ? (
                                orderBy === "DESC" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                ) : (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </th>
                            <th
                              className="cursor-pointer"
                              onClick={() => {
                                setSortBy("conversation_topic");
                                setClickable("conversation_topic");
                                setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                              }}
                            >
                              Conversation Topic
                              {sortBy === "conversation_topic" && clickable === "conversation_topic" ? (
                                orderBy === "DESC" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                ) : (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </th>

                            <th
                              className="cursor-pointer"
                              onClick={() => {
                                setSortBy("name");
                                setClickable("name");
                                setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                              }}
                            >
                              Name
                              {sortBy === "name" && clickable === "name" ? (
                                orderBy === "DESC" ? (
                                  <MdOutlineKeyboardArrowDown
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                ) : (
                                  <MdOutlineKeyboardArrowUp
                                    className="sort-icon cursor-pointer"
                                    size={14}
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </th>
                            <th className="action-col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loader ? (
                            <>
                              {bloombergList && bloombergList.length > 0 ? (
                                bloombergList.map((item: any, index: any) => {
                                  const isRowActive =
                                    selectedPost && selectedPost.id === item.id;
                                  return (
                                    <tr className={`${item.reviewClass} ${isRowActive ? "active-row" : ""}`}>
                                      <td>
                                        {complainceUserLogAccess && role !== "BASIC_USER" ? (
                                          props.excalated || props.reviewAllFlag ? (
                                            <input
                                              type="checkbox"
                                              className="me-2"
                                              checked={item.check}
                                              id={item.id}
                                              onChange={() => {
                                                handleCheckboxChange(item);
                                              }}
                                            />
                                          ) : (
                                            <input
                                              type="checkbox"
                                              className="me-2"
                                              checked={item.bulkReview}
                                              id={item.id}
                                              onChange={() => {
                                                handleBulkReviewCheckboxChange(item);
                                              }}
                                            />
                                          )
                                        ) : (
                                          ""
                                        )}

                                        <span className="me-1 icon-flag">
                                          {item.markedAsFlag ? (
                                            <HiMiniFlag
                                              size={16}
                                              className=" text-danger opacity-50"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <Link
                                          to=""
                                          onClick={() => {
                                            setId(item.id);
                                            toggleViewSidebar(true);
                                            setSelectedPost(item);
                                            setSelectedIndex(index);
                                          }}
                                        >
                                          {HelperService.getFormatedDateForDetail(item.lastMessageSentTime)}
                                        </Link>
                                      </td>
                                      <td>{item.conversationId}</td>
                                      <td>
                                        <TooltipCustom
                                          st={<span>{HelperService.truncate(item.conversationTopic, 25)}</span>}
                                          message={item.conversationTopic}
                                          position="top"
                                          id="c_btn"
                                        >
                                        </TooltipCustom>
                                      </td>
                                      <td>{item.name}</td>
                                      <td>
                                        <div className="action-btns text-center">
                                          <Button
                                            className="action-btn btn-view"
                                            title="View"
                                            onClick={() => {
                                              setId(item.id)
                                              setSelectedPost(item);
                                              toggleViewSidebar(true);
                                              setSelectedIndex(index);
                                            }}
                                          >
                                            <MdOutlineRemoveRedEye className="icon" />{" "}
                                            View
                                          </Button>
                                        </div>{" "}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    <div className="table-wrap position-relative w-100">
                                      <div className="no-data-found w-100">
                                        <div className="text-center">
                                          <img
                                            src={NoDataFound}
                                            width={110}
                                            className="mb-3"
                                            alt={""}
                                          />
                                          <h3 className="text-secondary font-12">
                                            No Record Found
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <TableLoader customClass={"remove-loader-height"} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Card>
                    {
                      !loader &&
                      <AIPagination
                        pagination={100}
                        totalItems={totalCount}
                        changePage={(page: number) => {
                          setPage(page);
                        }}
                        changeCurrentPage={page}
                      />
                    }
                  </div>
                </div>

                <div>
                  <div
                    className={`filter-container ${isActive ? "open-filter" : "postViewHeight"}`}
                  >
                    <Card className="filter-card border">
                      <Card.Header>
                        <h3 className="card-title">Bloomberg</h3>
                        <a
                          className="cursor-pointer close-btn"
                          onClick={() => { setSelectedIndex(null); toggleViewSidebar(false) }}
                        >
                          <CgClose />
                        </a>
                      </Card.Header>
                      <BloombergViewBlade
                        isReviewed={(bool: boolean) => {
                          if (props.isReviewed) {
                            props.isReviewed(bool);
                          }
                        }}
                        post={selectedPost}
                        id={id}
                        automark={automark}
                        parentCallback={handleCallback}
                        changedObject={selectedPost}
                        sendSlackData={(obj: any) => {
                          if (props.excalated) {
                            getBloombergList(page);
                          } else {
                            if (obj.changedPost != null) {
                              const myNextList = [...bloombergList];
                              const post1: any = myNextList.find(
                                (a) => a.id === obj.changedPost.id
                              );
                              post1.markedAsFlag = obj.changedPost.markedAsFlag;
                              post1.markedAsView = obj.changedPost.markedAsView;
                              post1.escalateAndSend = obj.changedPost.escalateAndSend;
                              post1.notes = obj.changedPost.notes;

                              if (post1.reviewClass !== null) {
                                post1.reviewClass = obj.changedPost.reviewClass;
                              }
                              setBloombergList(myNextList);
                              setSelectedPost(obj.changedPost);
                            }
                          }
                        }}
                        indexItem={selectedIndex}
                        nextItem={(index: any) => {
                          var ind = index + 1;
                          if (ind === 100) {
                            setPage(page + 1);
                          } else {
                            setSelectedIndex(ind)
                            if (bloombergList[ind]) {
                              setId(bloombergList[ind].id)
                              setSelectedPost(bloombergList[ind]);
                            }
                          }
                        }}

                      />
                    </Card>
                  </div>
                  {isConversation ? (
                    <div
                      className={`filter-container ${isActive ? "open-filter" : "postViewHeight"
                        }`}
                    >
                      <Card className="filter-card border">
                        <Card.Header>
                          <h3 className="card-title">Reply</h3>
                          <a
                            className="cursor-pointer close-btn"
                            onClick={() => toggleConversationSlideBar(false)}
                          >
                            <CgClose />
                          </a>
                        </Card.Header>
                        <BloombergConversationBlade
                          smsConversationData={bloombergConversationData}
                          replyId={replyId}
                          id={chatId}
                        />
                      </Card>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}

      <Offcanvas
        show={showReviewNamePopup}
        onHide={() => setShowReviewNamePopup(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Review Name</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-0 py-3">
          <ReviewNamePopup socialType={"BLOOMBERG"} reviewId={reviewId} filterObject={props.filterObject} list={bloombergList} parentcallback={() => setShowReviewNamePopup(false)} />
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};

export default BloombergTab;

import {
  Button,
  FloatingLabel,
  Form,
  Offcanvas,
  Col,
  Row,
} from "react-bootstrap";

import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import WebService from "../../Services/WebService";
import FbLogo from "../../assets/images/fb-logo.svg";
import Xlogo from "../../assets/images/socialChannels/twitter_icon.svg";
import InstgramLogo from "../../assets/images/insta-logo.svg";
import linkdnLogo from "../../assets/images/linkedin-logo.svg";
import NoImage from "../../assets/images/noImage.png";
import HelperService from "../../Services/HelperService";
import { useEffect, useRef, useState } from "react";
import { Label } from "../Common/Label/Label";
import moment from "moment";
import youtubeLogo from "../../assets/images/socialChannels/youtube-logo.svg"
import { useSelector } from "react-redux";
import stocktwitsLogo from "../../assets/images/socialChannels/stacktwits-icon.png";
import WMDateRangePicker from "../Common/WMDateRangePicker/WMDateRangePicker";
import { BsBookmarkFill } from "react-icons/bs";
import { toast } from "react-toastify";
interface propsData {
  sendFilterData?: any;
  socialType?: any;
  filter?: any;
  reviewTab?: any;
  isExport?: any;
  escalatedTab?: any;
  fromSavedFilter?: any;
  filterName?: any;
  filterId?: any;
}

const FilterBlade = (props: propsData) => {
  const role = useSelector((state: any) => state.uR.role);
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    reset,
  } = useForm();

  var watchVariable = watch();
  const [selectedAccount, setSelectedAccount] = useState<any[]>(
    props?.filter?.accounts
  );

  const [selectedAccountType, setSelectedAccountType] = useState<any>({
    value: props?.filter?.accountType,
    label: HelperService.removeUnderScore(props?.filter?.accountType),
  });
  const [error1, setError1] = useState<any>("");
  const [startDate, setStartDate] = useState<any>(props?.filter?.startDate !== undefined ? props?.filter?.startDate : new Date());
  const [endDate, setEndDate] = useState<any>(props?.filter?.endDate !== undefined ? props?.filter?.endDate : new Date());
  const [keywords, setKeywords] = useState<any>(props?.filter?.keywords);
  const [notes, setNotes] = useState<any>(props?.filter?.notes);
  const [endDateFlag, setEndDateFlag] = useState(false);
  const [checkbox, setCheckbox] = useState({
    markedAsFlag: props.filter?.markedAsFlag || undefined,
    markedAsView: props.filter?.markedAsView || undefined,
    escalateAndSend: props.filter?.escalateAndSend || undefined,
    flaggedNotReviewed: props.filter?.flaggedNotReviewed || undefined,
    notReviewed: props.filter?.notReviewed || undefined,
  });
  const [percentage, setPercentage] = useState<any>(
    props?.filter?.percentage
  );

  const selectedAcRef = useRef<any>(null);
  const selectedAcTypeRef = useRef<any>(null);

  const [date_range, setdateRange] = useState<any>({
    startDate: props?.filter?.startDate !== undefined ? props?.filter?.startDate : new Date(),
    endDate: props?.filter?.endDate !== undefined ? props?.filter?.endDate : new Date(),
    key: "selection",
  });
  const [filterNameErr, setFilterNameErr] = useState<any>("");
  const [filterName, setFilterName] = useState<any>(props.filterName);
  const [showFilterNameBlade, setShowFilterNameBlade] = useState(false);

  const [allFacebookAccounts, setAllFacebookAccounts] = useState<any>([]);
  const [allInstagramPAccounts, setAllInstagramPAccounts] = useState<any>([]);
  const [allLinkedInAccounts, setAllLinkedInAccounts] = useState<any>([]);
  const [allTwitterPAccounts, setAllTwitterPAccounts] = useState<any>([]);
  const [allTwitterBAccounts, setAllTwitterBAccounts] = useState<any>([]);
  const [allYoutubeAccounts, setAllYoutubeAccounts] = useState<any>([]);
  const [allStocktwitsAccounts, setAllStocktwitsAccounts] = useState<any>([]);

  const onAccountSelection = (selectedOption: any) => {
    if (props.socialType === "Facebook") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];

      if (isSelectAllSelected) {
        arr = allFacebookAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption.filter((option: any) => option.value !== "SELECT_ALL");
      }

      setSelectedAccount(arr);
    }

    if (props.socialType === "XBusiness") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterBAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }
      setSelectedAccount(arr);
    }

    if (props.socialType === "XPersonal") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedAccount(arr);
    }

    if (props.socialType === "LinkedIn") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allLinkedInAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedAccount(arr);
    }

    if (props.socialType === "InstagramBusiness") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");

      let arr: any = [];
      if (isSelectAllSelected) {

        arr = allInstagramPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedAccount(arr);
    }

    if (props.socialType === "YouTube") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allYoutubeAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedAccount(arr);

    }

    if (props.socialType === "Stocktwits") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allStocktwitsAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedAccount(arr);
    }

  };


  const handleCheckboxChange = (key: string, value: boolean) => {
    if (key === "markedAsView" && value) {
      setCheckbox((prev) => ({
        ...prev,
        markedAsView: true,
        notReviewed: false,
      }));
    } else if (key === "notReviewed" && value) {
      setCheckbox((prev) => ({
        ...prev,
        markedAsView: false,
        notReviewed: true,
      }));
    } else {
      setCheckbox((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };


  const accountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=${props.socialType === "InstagramPersonal" || props.socialType === "InstagramBusiness" ? "INSTAGRAM" :
          props.socialType === "LinkedInPersonal" || props.socialType === "LinkedInBusiness" || props.socialType === "LinkedIn" ? "LINKEDIN" :
            props.socialType === "XPersonal" || props.socialType === "XBusiness" ? "X" :
              props.socialType.toUpperCase()
          }&account_type=${props.socialType === "InstagramPersonal" ? "PERSONAL" :
            props.socialType === "InstagramBusiness" ? "BUSINESS" :
              props.socialType === "LinkedInPersonal" ? "PERSONAL" :
                props.socialType === "LinkedInBusiness" ? "PERSONAL" :
                  props.socialType === "LinkedIn" ? "PERSONAL" :
                    props.socialType === "XPersonal" ? "PERSONAL" :
                      props.socialType === "XBusiness" ? "BUSINESS" :
                        props.socialType === "Facebook" ? "BUSINESS" :
                          props.socialType === "YouTube" ? "PERSONAL" :
                            "BUSINESS"
          }&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.length > 0) {
            var instagramOption: any[] = [];
            var twitterOption: any[] = [];
            var facebookOption: any[] = [];
            var linkdnOption: any[] = [];
            var youtubeOption: any[] = [];
            var stocktwitsOption: any[] = [];

            res.forEach((channel: any) => {
              var obj = {
                value: channel.id,
                // label: (<span className="ms-3 font-14">{channel.name}</span>),
                label: channel?.accountStatus === "DELETED" ? `${channel.name} (Deleted)` : channel.name,
                social_type: channel.socialType,
              };
              if (channel.socialType === "INSTAGRAM") {
                instagramOption.push(obj);
              } else if (channel.socialType === "X") {
                twitterOption.push(obj);
              } else if (channel.socialType === "FACEBOOK") {
                facebookOption.push(obj);
              } else if (channel.socialType === "LINKEDIN") {
                linkdnOption.push(obj);
              } else if (channel.socialType === "YOUTUBE") {
                youtubeOption.push(obj);
              } else if (channel.socialType === "STOCKTWITS") {
                stocktwitsOption.push(obj);
              }

            });
            setAllInstagramPAccounts(instagramOption);
            setAllTwitterBAccounts(twitterOption);
            setAllTwitterPAccounts(twitterOption);
            setAllFacebookAccounts(facebookOption);
            setAllLinkedInAccounts(linkdnOption);
            setAllYoutubeAccounts(youtubeOption);
            setAllStocktwitsAccounts(stocktwitsOption);

            var groupedOptions = [];
            if (instagramOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={InstgramLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Instagram"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...instagramOption],

              });
            }
            if (twitterOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={Xlogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    X
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...twitterOption],
              });
            }
            if (facebookOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={FbLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Facebook"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...facebookOption],

              });
            }
            if (linkdnOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={linkdnLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Linkedin"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...linkdnOption],

              });
            }
            if (youtubeOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={youtubeLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"YouTube"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...youtubeOption],

              });
            }
            if (stocktwitsOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={stocktwitsLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Stocktwits"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: "Select All" }, ...stocktwitsOption],

              });
            }

            resolve(groupedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });


  const handleFilterApply = () => {
    if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
      setError("percentage", {
        type: "custom",
        message: "Please enter a valid number between 1 and 100",
      });
      return false;
    }
    if (error1 !== "") {
      return false;
    }
    if (moment(startDate).isAfter(endDate, 'day')) {
      setEndDateFlag(true);
      return;
    } else {
      setEndDateFlag(false);
    }
    let userArr: any = [];
    let grpArr: any = [];
    selectedOptions.forEach((reviewer: any) => {
      if (reviewer.category === "user") {
        userArr.push(reviewer);
      } else {
        grpArr.push(reviewer);
      }
    });
    var obj = {
      accounts: selectedAccount,
      accountType: selectedAccountType?.value,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
      keywords: keywords,
      percentage: watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
      sampleCount: watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
      notes: notes,
      markedAsFlag: checkbox?.markedAsFlag,
      markedAsView: checkbox?.markedAsView,
      escalateAndSend: checkbox?.escalateAndSend,
      flaggedNotReviewed: checkbox?.flaggedNotReviewed,
      notReviewed: checkbox?.notReviewed,
      userList: userArr,
      groupList: grpArr,
    };

    props.sendFilterData(obj, props.socialType, props.isExport);

  };

  const handleError = (error: any) => { };

  const resetFilter = () => {
    props.sendFilterData({}, props.socialType);
    selectedAcRef?.current?.clearValue();
    selectedAcTypeRef?.current?.clearValue();

    setNotes("");
    reset();
    setSelectedAccount([]);
    setSelectedAccountType(null);
    setStartDate("");
    setEndDate("");
    setKeywords("");
    setCheckbox({
      markedAsFlag: undefined,
      markedAsView: undefined,
      escalateAndSend: undefined,
      flaggedNotReviewed: undefined,
      notReviewed: undefined,
    });
    setdateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    })
  };

  const dateChange = (date_range: any) => {
    setdateRange(date_range);
  };

  useEffect(() => {
    if (fromSavedFilter) {
      setValue("sampleReview", props.filter.percentage ? "PERCENTAGE" : "COUNT")
      setValue("percentage", props.filter.percentage ?? props.filter.sampleCount)
      let groupList = props.filter.groupList && props.filter.groupList?.length > 0 ? props.filter.groupList.map((group: any) => ({
        value: group.id,
        label: `${group.group_name}`,
        category: "group",
      })) : [];
      let userList = props.filter.userList && props.filter.userList?.length > 0 ? props.filter.userList.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
        category: "user"
      })) : [];
      setGroupOptions([...groupList]);
      setUserOptions([...userList]);
      setSelectedOptions([...groupList, ...userList]);

    } else {
      setValue("sampleReview", props?.filter?.sampleCount ? "COUNT" : "PERCENTAGE");
      setValue("percentage", props?.filter?.sampleCount ? props?.filter?.sampleCount : props?.filter?.percentage ? props?.filter?.percentage : 100);
    }

  }, [props.filter]);

  const [userOptions, setUserOptions] = useState<any>(props.filter.userList);
  const [groupOptions, setGroupOptions] = useState<any>(props.filter.groupList);
  const [selectedOptions, setSelectedOptions] = useState<any>(
    !props.filterId && (props.filter.userList?.length > 0 || props.filter.groupList?.length > 0)
      ? [...(props.filter.userList || []), ...(props.filter.groupList || [])]
      : []
  );
  const [fromSavedFilter, setFromSavedFilter] = useState<Boolean>(props?.fromSavedFilter ?? false);

  const fetchUsers = async (inputValue: any) => {
    try {
      var userResponse: any = [];
      userResponse = await WebService.getAPI({
        action: `users/list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return userResponse?.list?.map((user: any) => ({
        value: user.id,
        label: user.first_name + " " + user.last_name,
        category: "user",
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  const fetchGroups = async (inputValue: any) => {
    try {
      var groupResponse: any = [];
      groupResponse = await WebService.getAPI({
        action: `group-list?page=1&keyword=${inputValue}`,
        body: {},
        access_token: access_token,
        t_id: t_id
      });
      return groupResponse?.list?.map((group: any) => ({
        value: group.id,
        label: group.group_name,
        category: "group",
      }));
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  }

  const loadOptions = async (inputValue: any, callback: any) => {
    try {
      const [userOptions, groupOptions] = await Promise.all([
        fetchUsers(inputValue),
        fetchGroups(inputValue),
      ]);
      setUserOptions(userOptions);
      setGroupOptions(groupOptions);

      const applyTo = [
        userOptions?.length > 0 &&
        { value: "all_users", label: (<label className="text-secondary font-14" >Select All Users</label>), category: "user" },
        ...userOptions,
        groupOptions?.length > 0 &&
        { value: "all_groups", label: (<label className="text-secondary font-14" >Select All Groups</label>), category: "group" },
        ...groupOptions,
      ];
      return applyTo?.filter((ele: any) => ele);
    } catch (error) {
      return [];
    }
  };

  const handleChange = (selected: any) => {
    if (!selected) {
      setSelectedOptions([]);
      return;
    }

    const isAllUsersSelected = selected.some(
      (option: any) => option.value === "all_users"
    );
    const isAllGroupsSelected = selected.some(
      (option: any) => option.value === "all_groups"
    );

    if (isAllUsersSelected) {
      setSelectedOptions([
        ...userOptions.filter((option: any) => option.value !== "all_users"),
        ...selected.filter((option: any) => option.category !== "user"),
      ]);
    } else if (isAllGroupsSelected) {
      setSelectedOptions([
        ...groupOptions.filter((option: any) => option.value !== "all_groups"),
        ...selected.filter((option: any) => option.category !== "group"),
      ]);
    } else {
      setSelectedOptions(selected);
    }
  };

  const handleSaveFilter = () => {
    if (fromSavedFilter && !filterName) {
      setFilterNameErr("Please enter filter name");
      return false;
    }
    if (watchVariable.sampleReview === "PERCENTAGE" && watchVariable.percentage > 100) {
      setError("percentage", {
        type: "custom",
        message: "Please enter a valid number between 1 and 100",
      });
      return false;
    }
    if (!fromSavedFilter) {
      setShowFilterNameBlade(true);
    } else {
      handleSaveFilterWithName();
    }

  };

  const handleSaveFilterWithName = () => {
    setFilterNameErr("");
    if (!filterName && !props.filterName) {
      setFilterNameErr("Please enter filter name");
      return false;
    }
    let userArr: any = [];
    let grpArr: any = [];
    selectedOptions.forEach((reviewer: any) => {
      if (reviewer.category === "user") {
        userArr.push(reviewer);
      } else {
        grpArr.push(reviewer);
      }
    });
    var filter = {
      accountType: selectedAccountType?.value,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
      keywords: keywords,
      percentage: watchVariable.sampleReview === "PERCENTAGE" ? parseInt(watchVariable.percentage) : null,
      sampleCount: watchVariable.sampleReview === "COUNT" ? parseInt(watchVariable.percentage) : null,
      notes: notes,
      markedAsFlag: checkbox?.markedAsFlag,
      markedAsView: checkbox?.markedAsView,
      escalateAndSend: checkbox?.escalateAndSend,
      flaggedNotReviewed: checkbox?.flaggedNotReviewed,
      notReviewed: checkbox?.notReviewed,
    }


    if (props.filterId) {
      WebService.addLoader("apply1");
      return WebService.putAPI({
        action: `filter/update?id=${props.filterId}`,
        body: {
          "filter": {
            ...filter,
            "accounts": selectedAccount && selectedAccount?.length > 0 ? selectedAccount.map((account: any) => ({ "id": account.id ? account.id : account.value ? account.value : "", "label": account?.label, "social_type": account?.social_type })) : [],
            "userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
            "groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
          },
          "name": filterName,
          "type": getType(props.socialType),
          "accountType": props.socialType === "XPersonal" ? "PERSONAL" : props.socialType === "XBusiness" ? "BUSINESS" : ""
        },
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          toast.success(res?.message);
          WebService.removeLoader("apply1");
          setFromSavedFilter(false);
          setShowFilterNameBlade(false);
          props.sendFilterData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
        })
        .catch((error: any) => {
          WebService.removeLoader("apply1");
          return error;
        });
    } else {
      WebService.addLoader("apply");
      return WebService.postAPI({
        action: `filter/save`,
        body: {
          "filter": {
            ...filter,
            "accounts": selectedAccount && selectedAccount?.length > 0 ? selectedAccount.map((account: any) => ({ "id": account.id ? account.id : account.value ? account.value : "", "label": account?.label, "social_type": account?.social_type })) : [],
            "userList": userArr && userArr.length > 0 ? userArr.map((user: any) => user.value) : [],
            "groupList": grpArr && grpArr.length > 0 ? grpArr.map((group: any) => group.value) : []
          },
          "name": filterName,
          "type": getType(props.socialType),
          "accountType": props.socialType === "XPersonal" ? "PERSONAL" : props.socialType === "XBusiness" ? "BUSINESS" : ""

        },
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          toast.success(res?.message);
          WebService.removeLoader("apply");
          props.sendFilterData({ ...filter, "userList": userArr, "groupList": grpArr }, "FILTER");
          setShowFilterNameBlade(false);
        })
        .catch((error: any) => {
          WebService.removeLoader("apply");
          return error;
        });
    }
  };

  const getType = (socialType: any) => {
    switch (socialType) {
      case "InstagramBusiness": return "INSTAGRAM";
      case "LinkedIn": return "LINKEDIN";
      case "XPersonal": return "X";
      case "XBusiness": return "X";
      case "YouTube": return "YOUTUBE";
      case "Facebook": return "FACEBOOK";
      case "Stocktwits": return "STOCKTWITS";
      default: return "";
    }
  };

  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{props.isExport ? "Export" : "Filters"}</Offcanvas.Title>
      </Offcanvas.Header>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleFilterApply, handleError)}
      >
        <Offcanvas.Body className="px-0 form-style ">
          <div className="px-3 mt-3">
            {
              fromSavedFilter &&
              <div className="mt-1 mb-3">
                <FloatingLabel
                  controlId="filtername"
                  label="Filter Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    onChange={(e: any) => { setFilterNameErr(""); setFilterName(e.target.value) }}
                    placeholder="Filter Name"
                    defaultValue={filterName}
                  />
                  <span style={{ display: filterNameErr ? "block" : "none" }} className="px-1 text-danger font-12">{filterNameErr}</span>
                </FloatingLabel>
              </div>
            }

            <>
              <AsyncSelect
                ref={selectedAcRef}
                cacheOptions
                defaultOptions
                onChange={onAccountSelection}
                isMulti
                loadOptions={(inputValue: string) => accountPromiseOptions(inputValue)}
                placeholder="Select Account"
                value={selectedAccount}
              />
            </>

            <div className="mt-3">
              <WMDateRangePicker
                selectData={(date_range: any) => {
                  dateChange(date_range);
                }}
                date_range={date_range}
              >
              </WMDateRangePicker>
            </div>
            <div className=" mb-3">
              <FloatingLabel
                controlId="Keywords"
                label="Keywords"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  onChange={(e: any) => {
                    setKeywords(e.target.value);
                  }}
                  placeholder="Keywords"
                  value={keywords}
                />
              </FloatingLabel>
            </div>
            {
              props.reviewTab || props.escalatedTab ?
                "" :
                <Row>
                  <div className="mb-3">
                    <div className="d-flex gap-1">
                      <Col lg={6}>
                        <FloatingLabel controlId="reviewSample" label="Review sample" className="">
                          <Form.Select
                            aria-label="Select"
                            {...register("sampleReview", {
                              required: "Please select review sample type",
                            })}
                            onChange={(e: any) => { clearErrors("percentage"); setValue("sampleReview", e.target.value) }}
                          >
                            <option
                              value=""
                              disabled={true}
                            >
                              Select type
                            </option>
                            <option selected value="PERCENTAGE">Percentage</option>
                            <option value="COUNT">Count</option>
                          </Form.Select>
                        </FloatingLabel>
                        {errors.sampleReview && (
                          <Label
                            title={errors.sampleReview.message?.toString()}
                            modeError={true}
                            showStar={true}
                            type=""
                          />
                        )}
                      </Col>

                      <Col lg={6}>
                        <FloatingLabel controlId="ReviewPercentage" label="Sample Quantity" className="">
                          <Form.Control
                            type="text"
                            {...register("percentage", {
                              required: "Please enter value",
                              validate: (value) => {
                                if (watchVariable.sampleReview === "PERCENTAGE") {
                                  if (value === "") return "Please enter value";
                                  if (!/^[1-9]\d?$|^100$/.test(value)) {
                                    return "Please enter a valid number between 1 and 100";
                                  }
                                }
                                return true;
                              },
                            })}
                            defaultValue={100}
                            onChange={(e: any) => {
                              const enteredValue = e.target.value;
                              if (watchVariable.sampleReview === "PERCENTAGE" && parseInt(enteredValue) > 100) {
                                setError("percentage", {
                                  type: "custom",
                                  message: "Please enter a valid number between 1 and 100",
                                });
                                setValue("percentage", enteredValue);
                              } else {
                                clearErrors("percentage");
                                setValue("percentage", enteredValue);
                              }
                            }}
                          />
                          {errors.percentage && (
                            <Label
                              title={errors.percentage.message?.toString()}
                              modeError={true}
                              showStar={true}
                              type=""
                            />
                          )}
                        </FloatingLabel>
                      </Col>
                    </div>
                  </div>
                </Row>
            }


          </div>
          <div className="px-3 mb-3">
            <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={handleChange}
              placeholder="Select Users or Groups"
              value={selectedOptions}
              className="scroll-verticle"
            />
          </div>
          <div className="px-4 mb-2">
            <div className="mb-1 text-nowrap">
              <Row className="row-cols-4 mb-2 d-flex gap-2" >
                <div className="px-2 py-1 bg-danger-subtle">
                  <Form.Check
                    type="checkbox"
                    id="marked_As_Flag_Social"
                    label="Flagged"
                    className="labe-text-dark checkbox-danger"
                    checked={checkbox.markedAsFlag}
                    onChange={(event) =>
                      handleCheckboxChange("markedAsFlag", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-success-subtle">
                  <Form.Check
                    type="checkbox"
                    id="marked_As_View_Social"
                    label="Reviewed"
                    className="labe-text-dark checkbox-success"
                    checked={checkbox.markedAsView}
                    onChange={(event) =>
                      handleCheckboxChange("markedAsView", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-warning-subtle">
                  <Form.Check
                    type="checkbox"
                    id="escalate_And_Send_Social"
                    label="Escalate"
                    className="labe-text-dark checkbox-warning"
                    checked={checkbox.escalateAndSend}
                    onChange={(event) =>
                      handleCheckboxChange(
                        "escalateAndSend",
                        event?.target?.checked
                      )
                    }
                  />
                </div>
              </Row>
              <Row className="mb-2 d-flex gap-2">
                <div className="px-2 py-1 bg-primary-subtle col-6 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-primary"
                    id="Reviewed_Social_1"
                    label="Flagged but not reviewed"
                    checked={checkbox.flaggedNotReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("flaggedNotReviewed", event?.target?.checked)
                    }
                  />
                </div>
                <div className="px-2 py-1 bg-secondary-subtle col-4 text-nowrap">
                  <Form.Check
                    type="checkbox"
                    className="labe-text-dark checkbox-secondary"
                    id="Reviewed_Social_2"
                    label="Not yet reviewed"
                    checked={checkbox.notReviewed}
                    onChange={(event) =>
                      handleCheckboxChange("notReviewed", event?.target?.checked)
                    }
                  />
                </div>
              </Row>
            </div>
          </div>

          <div className=" form-style">
            <div className="px-3">
              <div className="mt-3 d-flex gap-3">
                {
                  role !== "BASIC_USER" ?
                    <Button
                      id="apply1"
                      type="button"
                      className="btn btn-brand-light w-100"
                      onClick={() => handleSaveFilter()}
                    >
                      <BsBookmarkFill className="icon" /> Save Filter
                    </Button>
                    : ""

                }
                {!props.fromSavedFilter && (
                  <Button
                    type="submit"
                    id="apply-btn"
                    className="btn btn-brand-1 w-100"
                    onClick={() => {
                      handleFilterApply()
                    }
                    }>
                    Apply
                  </Button>
                )}
              </div>
              {
                props.isExport || props.fromSavedFilter ?
                  "" :
                  <Col
                    className="item-align-end font-12 mt-2"
                    style={{ textAlign: "end" }}
                  >
                    <a
                      onClick={() => resetFilter()}
                      className="font-14 cursor-pointer"
                    >
                      Reset Filter
                    </a>
                  </Col>

              }

            </div>
          </div>
        </Offcanvas.Body>
      </Form>

      <Offcanvas
        show={showFilterNameBlade}
        onHide={() => setShowFilterNameBlade(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Name</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-3 py-3">
          <div className=" form-style">
            <div className="mt-3">
              <div className="mt-3">
                <FloatingLabel
                  controlId="filterName"
                  label="Filter Name"
                  className=""
                >
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      setFilterNameErr("");
                      setFilterName(e.target.value);
                    }}
                    placeholder="Filter Name"
                  />
                </FloatingLabel>
              </div>
              <span className="text-danger font-12">{filterNameErr}</span>
              <div className="mt-3 mb-2">
                <Button
                  type="button"
                  className="btn btn-brand-1 w-100"
                  id={props.filterId ? "apply1" : "apply"}
                  onClick={() => handleSaveFilterWithName()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};
export default FilterBlade;

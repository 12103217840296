import { Offcanvas, Col, Row, Card, FloatingLabel, Button, Form, Spinner, } from "react-bootstrap"
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { Label } from "../Common/Label/Label";
import { useForm } from "react-hook-form";
import TableLoader from "../Common/TableLoader/TableLoader";
import { toast } from "react-toastify";
import { BiDownload } from "react-icons/bi";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";
import HelperService from "../../Services/HelperService";
import "./social-review.scss";
import NoImage from "../../assets/images/noImage.png"
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import { ImReply } from "react-icons/im";
import { useSelector } from "react-redux";
import { AiOutlineSend } from "react-icons/ai";


interface propsData {
    id?: any;
    automark: any;
    post: any;
    sendSlackData?: any;
    changedObject?: any;
    parentCallback?: any;
    isReviewed?: any;
    indexItem?: any;
    nextItem?: any;
}

const BloombergViewBlade = (props: propsData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const role = useSelector((state: any) => state.uR.role);
    const [page, setPage] = useState<any>(1);
    const [hasMore, setHasMore] = useState(true);
    const [reviewFlag, setReviewFlag] = useState(false);
    const [loader, setLoader] = useState<any>(false);
    let [changedPost, setChangedPost] = useState<any>(null);
    const [chatList, setChatList] = useState<any>([]);
    const [chatReplyList, setChatReplyList] = useState<any>([]);
    const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
    const [showReplyPopup, setShowReplyPopup] = useState<any>(false);
    useEffect(() => {
        var data1 = atob(localStorage.getItem('ucd') ?? "");
        if (role === "COMPLIANCE_OFFICER") {
            if ((data1 === "" || data1 === undefined)) {
            } else {
                var json = JSON.parse(data1);
                setComplainceUserLogAccess(json.reviewLogs)
            }
        }
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm();
    var watchVariable = watch();

    useEffect(() => {
        if (props.id !== undefined) {
            localStorage.setItem("previousNotes", props.post.notes);
            getBloombergChatList(1)
        }

        if (
            (props.post !== null && changedPost == null) ||
            (props.post !== null &&
                changedPost != null &&
                props.post.id !== changedPost.id)
        ) {
            setLoader(true);
            setChangedPost(props.post);
            watchVariable.notes = props.post.notes ?? "";
            setValue("notes", props.post.notes ?? "");
            watchVariable.markedAsFlag = props.post.markedAsFlag ?? "";
            setValue("markedAsFlag", props.post.markedAsFlag ?? "");
            watchVariable.markedAsView = props.post.markedAsView ?? "";
            setValue("markedAsView", props.post.markedAsView ?? "");
            watchVariable.escalateAndSend = props.post.escalateAndSend ?? "";
            setValue("escalateAndSend", props.post.escalateAndSend ?? "");

            if (props.automark) {
                watchVariable.markedAsView = true;
                setValue("markedAsView", true);
                handleAddNotes(props.post, true);
            }
        }
        setLoader(false);
        setChatList([]);

    }, [props.post, props.automark]);


    const getBloombergChatList = (pg?: any) => {
        if (props.id === undefined) {
            return false
        }
        setLoader(true);
        return WebService.getAPI({
            action: `bloomberg/chats?id=${props.id}&page=${pg}`,
            body: null,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                if (res?.list?.length > 0) {
                    setChatList((prevList: any) => [...prevList, ...res.list]);
                }
                if (res.nextOffset === -1) {
                    setHasMore(false);
                } else {
                    setPage((prevPage: any) => prevPage + 1);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    };

    const handleAddNotes = (post?: any, showSuccess?: any) => {
        // return false;
        if (role === "BASIC_USER") {
            return false;
        }
        if (!complainceUserLogAccess) {
            return false;
        }

        if (
            props.automark && props.post.markedAsView
            && props.post.notes === watchVariable.notes
            && props.post.markedAsFlag === watchVariable.markedAsFlag
            && props.post.escalateAndSend === watchVariable.escalateAndSend
        ) {
            setValue("markedAsView", true);
            return false
        }
        WebService.addLoader("save");
        if (post.id !== undefined) {
            changedPost = post;
        }
        watchVariable.id = props.post.id;
        let reviewClass = "";
        if (watchVariable.markedAsFlag) {
            reviewClass = "flag";
        }
        if (watchVariable.markedAsView) {
            reviewClass = reviewClass + " " + "mardk-as-viewed";
        }
        if (watchVariable.escalateAndSend) {
            reviewClass = reviewClass + " " + "escalate-send";
        }
        if (changedPost.reviewClass !== null) {
            changedPost.reviewClass = reviewClass;
        }
        changedPost.markedAsFlag = watchVariable.markedAsFlag;
        changedPost.markedAsView = watchVariable.markedAsView;
        changedPost.escalateAndSend = watchVariable.escalateAndSend;
        changedPost.notes = watchVariable.notes;
        changedPost.id = props.id;
        return WebService.putAPI({
            action: `bloomberg/headers`,
            body: watchVariable,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                if (!props.automark || !showSuccess || showSuccess?.type === "submit") {
                    toast.success(res.message);
                }
                var obj = {
                    changedPost: changedPost,
                    post: post.id !== undefined ? post : props.post
                };
                props.isReviewed(true);
                props.sendSlackData(obj);
                WebService.removeLoader("save");
            })
            .catch((error: any) => {
                WebService.removeLoader("save");
                return error;
            });
    };
    const handleError = () => { };

    const handleReply = (id: any) => {
        props.parentCallback(true, props.post, id)
    }



    return (
        <>
            {!loader ? (
                <Form
                    className="w-100 form-style h-100"
                    name="Verify"
                    id="Verify"
                    onSubmit={handleSubmit(handleAddNotes, handleError)}
                >
                    <div className="d-flex justify-content-end card-header">
                        <div className="d-flex text-end">
                            <Button
                                className="text-nowrap action-btn btn-brand-1 font-12 px-3 py-1 me-2 nextbutton"
                                title="Next"
                                onClick={() => {
                                    props.nextItem(props.indexItem);
                                }}
                            >
                                <AiOutlineSend className="align-text-center" size={11} style={{ marginRight: '5px' }} />
                                Next
                            </Button>
                        </div>
                    </div>
                    <Offcanvas.Body className="px-0 py-3">
                        <div className="px-3" >
                            <div className="chat-box h-auto" id="bloomberg_chat_list">
                                <InfiniteScroll
                                    scrollableTarget="bloomberg_chat_list"
                                    dataLength={chatList.length}
                                    next={() => getBloombergChatList(page)}
                                    hasMore={hasMore}
                                    loader={
                                        <Row className="mb-3 mx-0" style={{ justifyContent: "center" }}>
                                            <Spinner animation="border" variant="primary" />
                                        </Row>
                                    }
                                >
                                    {
                                        chatList?.map((item: any, index: any) => {
                                            return (
                                                <Row className="mb-3 mx-0" key={"blmbrg_" + index}>
                                                    <Col lg={8} className="d-flex gap-2 align-items-end">
                                                        <div>
                                                            {
                                                                item?.fileType === "FILE" ?
                                                                    <>
                                                                        {
                                                                            item?.message ?
                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    {item?.text}
                                                                                </Card>
                                                                                : ""

                                                                        }

                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                            {
                                                                                item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                    <span
                                                                                        className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill"
                                                                                        style={{ backgroundColor: "#655E93" }}
                                                                                        onClick={() => {
                                                                                            handleReply(item.thread_id)
                                                                                        }}
                                                                                    >
                                                                                        {item.thread_count}
                                                                                        <span className="visually-hidden">unread messages</span>
                                                                                    </span>
                                                                                    : ""
                                                                            }
                                                                            <Card className="p-4 border-brand h-100  text-center cursor-pointer" onClick={() => window.open(item?.mediaPath)} >
                                                                                <BiDownload size={30} className="mb-3 mx-auto icon" />
                                                                                <p className="font-500 font-16 mb-0">
                                                                                    <TooltipCustom
                                                                                        st={
                                                                                            <span>{HelperService.truncate(item?.fileName, 15)}</span>
                                                                                        }
                                                                                        message={item?.fileName}
                                                                                        position="top"
                                                                                        id="file"
                                                                                    />
                                                                                </p>
                                                                            </Card>
                                                                        </Card>
                                                                        <div className="d-flex gap-3 justify-content-end position-relative">

                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                {moment(item.messageCreatedTime)
                                                                                    .local()
                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                            </p>
                                                                        </div>

                                                                    </>
                                                                    : item?.fileType === "IMAGE" ?
                                                                        <>
                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                {
                                                                                    item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                        <span
                                                                                            className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill"
                                                                                            style={{ backgroundColor: "#655E93" }}
                                                                                            onClick={() => {
                                                                                                handleReply(item.thread_id)
                                                                                            }}
                                                                                        >
                                                                                            {item.thread_count}
                                                                                            <span className="visually-hidden">unread messages</span>
                                                                                        </span>
                                                                                        : ""
                                                                                }
                                                                                <img alt="" onClick={() => window.open(item?.mediaPath)} className='rounded-3 img-fluid object-fit-contain cursor-pointer'
                                                                                    style={{ maxHeight: "200px" }}
                                                                                    src={item?.mediaPath}
                                                                                    onError={(error: any) => {
                                                                                        error.target.src = NoImage;
                                                                                    }} />
                                                                            </Card>
                                                                            <div className="d-flex gap-3 justify-content-end">

                                                                                <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                    {moment(item.messageCreatedTime)
                                                                                        .local()
                                                                                        .format("MM-DD-YY hh:mm A")}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                        : item?.fileType === "VIDEO" ?
                                                                            <>
                                                                                <Card onClick={() => window.open(item?.mediaPath)} className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    {
                                                                                        item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                            <span
                                                                                                className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill"
                                                                                                style={{ backgroundColor: "#655E93" }}
                                                                                                onClick={() => {
                                                                                                    handleReply(item.thread_id)
                                                                                                }}
                                                                                            >
                                                                                                {item.thread_count}
                                                                                                <span className="visually-hidden">unread messages</span>
                                                                                            </span>
                                                                                            : ""
                                                                                    }
                                                                                    <video className='rounded-3 img-fluid object-fit-contain cursor-pointer' style={{ maxHeight: "200px" }} controls>
                                                                                        <source src={item?.mediaPath} type="video/mp4" />
                                                                                    </video>
                                                                                </Card>
                                                                                <div className="d-flex gap-3 justify-content-end">

                                                                                    <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                        {moment(item.messageCreatedTime)
                                                                                            .local()
                                                                                            .format("MM-DD-YY hh:mm A")}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                            : item?.fileType === "AUDIO" ?
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                        {
                                                                                            item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                                <span
                                                                                                    className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill"
                                                                                                    style={{ backgroundColor: "#655E93" }}
                                                                                                    onClick={() => {
                                                                                                        handleReply(item.thread_id)
                                                                                                    }}
                                                                                                >
                                                                                                    {item.thread_count}
                                                                                                    <span className="visually-hidden">unread messages</span>
                                                                                                </span>
                                                                                                : ""
                                                                                        }
                                                                                        <audio onClick={() => window.open(item?.mediaPath)} className='rounded-3 cursor-pointer' controls>
                                                                                            <source src={item?.mediaPath} type="audio/mpeg" />
                                                                                        </audio>
                                                                                    </Card>
                                                                                    <div className="d-flex gap-3 justify-content-end">

                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                            {moment(item.messageCreatedTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A")}
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                                : item?.fileType === "TEXT_MESSAGE" ?
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                            {
                                                                                                item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                                    <span
                                                                                                        className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill"
                                                                                                        style={{ backgroundColor: "#655E93" }}
                                                                                                        onClick={() => {
                                                                                                            handleReply(item.thread_id)
                                                                                                        }}
                                                                                                    >
                                                                                                        {item.thread_count}
                                                                                                        <span className="visually-hidden">unread messages</span>
                                                                                                    </span>
                                                                                                    : ""
                                                                                            }
                                                                                            <div dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(item?.message, item?.highlighted) }}></div>
                                                                                        </Card>
                                                                                        <div className="d-flex gap-3 justify-content-end">
                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                {moment(item.messageCreatedTime)
                                                                                                    .local()
                                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                            <p className="font-12 text-nowrap text-brand font-medium mb-2">{item?.senderName}</p>
                                                                                            <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word", textAlign: "left", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: item?.message }}></div>
                                                                                        </Card>
                                                                                        <div className="d-flex gap-3 justify-content-end">
                                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                {moment(item.createdDate)
                                                                                                    .local()
                                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                                            </p>
                                                                                        </div>
                                                                                    </>

                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </div>
                        </div>

                        <>
                            <hr className="border-secondary-subtle" />
                            <div className="px-3 mb-3 text-nowrap">
                                <div className="d-flex gap-2 mb-1">
                                    <div className="px-2 py-1 bg-danger-subtle">
                                        <Form.Check
                                            type="checkbox"
                                            id="slack_markedAsFlag"
                                            label="Flag"
                                            className="labe-text-dark checkbox-danger"
                                            checked={watchVariable.markedAsFlag}
                                            disabled={role !== "BASIC_USER" ? false : true}
                                            onChange={() => {
                                                if (watchVariable.markedAsFlag) {
                                                    watchVariable.markedAsFlag = false;
                                                    setValue("markedAsFlag", false);
                                                    setReviewFlag(false);
                                                } else {
                                                    watchVariable.markedAsFlag = true;
                                                    setValue("markedAsFlag", true);
                                                }
                                                handleAddNotes(props.post, true);
                                            }}
                                        />
                                    </div>
                                    <div className="px-2 py-1 bg-success-subtle">
                                        <Form.Check
                                            type="checkbox"
                                            id="slack_markedAsView"
                                            label="Mark as viewed"
                                            className="labe-text-dark checkbox-success"
                                            checked={watchVariable.markedAsView}
                                            disabled={role !== "BASIC_USER" ? false : true}
                                            onChange={() => {
                                                if (watchVariable.markedAsView) {
                                                    watchVariable.markedAsView = false;
                                                    setValue("markedAsView", false);
                                                    setReviewFlag(false);
                                                } else {
                                                    watchVariable.markedAsView = true;
                                                    setValue("markedAsView", true);
                                                }
                                                handleAddNotes(props.post, true);
                                            }}
                                        />
                                    </div>

                                    <div className="px-2 py-1 bg-warning-subtle">
                                        <Form.Check
                                            type="checkbox"
                                            id="slack_escalateAndSend"
                                            label="Escalate send"
                                            className="labe-text-dark checkbox-warning"
                                            checked={watchVariable.escalateAndSend}
                                            disabled={role !== "BASIC_USER" ? false : true}
                                            onChange={() => {
                                                if (watchVariable.escalateAndSend) {
                                                    watchVariable.escalateAndSend = false;
                                                    setValue("escalateAndSend", false);
                                                    setReviewFlag(false);
                                                } else {
                                                    watchVariable.escalateAndSend = true;
                                                    setValue("escalateAndSend", true);
                                                }
                                                handleAddNotes(props.post, true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr className=" border-secondary-subtle" />
                            <div className=" form-style">
                                <div className="px-3">
                                    <FloatingLabel controlId="Notes" label="Notes">
                                        <Form.Control
                                            {...register("notes")}
                                            as="textarea"
                                            placeholder="Notes"
                                            style={{ height: "100px" }}
                                            value={watchVariable.notes ? watchVariable.notes : ""}
                                            disabled={role !== "BASIC_USER" ? false : true}
                                        />
                                        {errors.notes && (
                                            <Label
                                                title="Please add notes"
                                                modeError={true}
                                                showStar={true}
                                                type=""
                                            />
                                        )}
                                    </FloatingLabel>
                                    {
                                        complainceUserLogAccess && role !== "BASIC_USER" ?
                                            <div className="mt-3">
                                                <Button
                                                    type="submit"
                                                    className="btn btn-brand-1 w-100"
                                                    id="save"
                                                >
                                                    Add Note
                                                </Button>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </div>
                        </>


                    </Offcanvas.Body>
                </Form >
            ) : (
                <TableLoader />
            )}


            {/* <Offcanvas show={showReplyPopup} onHide={() => setShowReplyPopup(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Change Password</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="px-0 py-3">
                    <div className="px-3">
                        <div className="chat-box h-auto">

                            <InfiniteScroll
                                dataLength={chatReplyList.length}
                                next={getBloombergChatList}
                                hasMore={hasMore}
                                loader={<div className='d-flex justify-content-center'><Spinner animation="border" variant="primary" /></div>}
                                endMessage={""}
                            >
                                {
                                    chatReplyList?.map((item: any, index: any) => {
                                        return (
                                            <Row className="mb-3 mx-0">
                                                <Col lg={8} className="d-flex gap-2 align-items-end">

                                                    <div>
                                                        {
                                                            item?.fileType === "FILE" ?
                                                                <>
                                                                    {
                                                                        item?.message ?
                                                                            <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                {item?.text}
                                                                            </Card>
                                                                            : ""

                                                                    }

                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                        <Card className="p-4 border-brand h-100  text-center cursor-pointer" onClick={() => window.open(item?.mediaPath)} >
                                                                            <BiDownload size={30} className="mb-3 mx-auto icon" />
                                                                            <p className="font-500 font-16 mb-0">
                                                                                <TooltipCustom
                                                                                    st={
                                                                                        <span>{HelperService.truncate(item?.fileName, 15)}</span>
                                                                                    }
                                                                                    message={item?.fileName}
                                                                                    position="top"
                                                                                    id="file"
                                                                                />
                                                                            </p>
                                                                        </Card>
                                                                    </Card>
                                                                    <div className="d-flex gap-3 justify-content-end">
                                                                        {
                                                                            item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                    <ImReply />
                                                                                </span>
                                                                                : ""
                                                                        }
                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                            {moment(item.messageCreatedTime)
                                                                                .local()
                                                                                .format("MM-DD-YY hh:mm A")}
                                                                        </p>
                                                                    </div>

                                                                </>
                                                                : item?.fileType === "IMAGE" ?
                                                                    <>
                                                                        <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                            <img alt="" onClick={() => window.open(item?.mediaPath)} className='rounded-3 img-fluid object-fit-contain cursor-pointer'
                                                                                style={{ maxHeight: "200px" }}
                                                                                src={item?.mediaPath}
                                                                                onError={(error: any) => {
                                                                                    error.target.src = NoImage;
                                                                                }} />
                                                                        </Card>
                                                                        <div className="d-flex gap-3 justify-content-end">
                                                                            {
                                                                                item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                    <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                        <ImReply />
                                                                                    </span>
                                                                                    : ""
                                                                            }
                                                                            <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                {moment(item.messageCreatedTime)
                                                                                    .local()
                                                                                    .format("MM-DD-YY hh:mm A")}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                    : item?.fileType === "VIDEO" ?
                                                                        <>
                                                                            <Card onClick={() => window.open(item?.mediaPath)} className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                <video className='rounded-3 img-fluid object-fit-contain cursor-pointer' style={{ maxHeight: "200px" }} controls>
                                                                                    <source src={item?.mediaPath} type="video/mp4" />
                                                                                </video>
                                                                            </Card>
                                                                            <div className="d-flex gap-3 justify-content-end">
                                                                                {
                                                                                    item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                        <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                            <ImReply />
                                                                                        </span>
                                                                                        : ""
                                                                                }
                                                                                <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                    {moment(item.messageCreatedTime)
                                                                                        .local()
                                                                                        .format("MM-DD-YY hh:mm A")}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                        : item?.fileType === "AUDIO" ?
                                                                            <>
                                                                                <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                    <audio onClick={() => window.open(item?.mediaPath)} className='rounded-3 cursor-pointer' controls>
                                                                                        <source src={item?.mediaPath} type="audio/mpeg" />
                                                                                    </audio>
                                                                                </Card>
                                                                                <div className="d-flex gap-3 justify-content-end">
                                                                                    {
                                                                                        item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                            <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                <ImReply />
                                                                                            </span>
                                                                                            : ""
                                                                                    }
                                                                                    <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                        {moment(item.messageCreatedTime)
                                                                                            .local()
                                                                                            .format("MM-DD-YY hh:mm A")}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                            : item?.fileType === "TEXT_MESSAGE" ?
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                        <div dangerouslySetInnerHTML={{ __html: HelperService.getHighlightedContent(item?.message, item?.highlighted) }}></div>
                                                                                    </Card>
                                                                                    <div className="d-flex gap-3 justify-content-end">
                                                                                        {
                                                                                            item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                                <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                    <ImReply />
                                                                                                </span>
                                                                                                : ""
                                                                                        }
                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                            {moment(item.messageCreatedTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A")}
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Card className="border-0 px-3 py-2 rounded-4 chat-corner-left chat-card">
                                                                                        <div dangerouslySetInnerHTML={{ __html: item?.text }}></div>
                                                                                    </Card>
                                                                                    <div className="d-flex gap-3 justify-content-end">
                                                                                        {
                                                                                            item.thread_id !== undefined && item.thread_id !== "" ?
                                                                                                <span className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                                    <ImReply />
                                                                                                </span>
                                                                                                : ""
                                                                                        }
                                                                                        <p className="font-10 text-nowrap mb-0 text-end font-light mt-1">
                                                                                            {moment(item.messageCreatedTime)
                                                                                                .local()
                                                                                                .format("MM-DD-YY hh:mm A")}
                                                                                        </p>
                                                                                    </div>
                                                                                </>

                                                        }


                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </InfiniteScroll>

                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas> */}
        </>
    )
}
export default BloombergViewBlade;

import { useEffect, useState } from "react";
import { Row, Col, Button, InputGroup, Form, Offcanvas, FloatingLabel, Dropdown } from "react-bootstrap"
import PageTitle from "../PageTitle";
import { IoSearchOutline } from "react-icons/io5";
import { BsFunnel } from "react-icons/bs";
import BloombergFilterBlade from "./FilterBlade";
import { useNavigate } from "react-router-dom";
import ReviewAllFilterBlade from "../ReviewAll/ReviewAllFilterBlade";
import "./social-review.scss"
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import WebService from "../../Services/WebService";
import BloombergTab from "./BloombergTab";
import { useSelector } from "react-redux";
import SavedFilterBlade from "../SavedFilterBlade/SavedFilterBlade";
interface propsData {
    viewObject?: any;
    changedObject?: any;
    keyWord?: any;
    excalated?: any;
    filterObject?: any;
    tab?: any;
    sendData?: any;
    automark?: any;
}
const BloombergReview = (props: propsData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const role = useSelector((state: any) => state.uR.role);
    var navigate = useNavigate();
    const [automark, setAutoMark] = useState(role !== "BASIC_USER" ? true : false);
    const [excalated, setEscalated] = useState(false);
    const [viewObject, setViewObject] = useState<any>(null);
    const [keyword, setKeyword] = useState("");
    const [changedObject, setChangedObject] = useState(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [reviewTab, setReviewTab] = useState("");
    const [filterFlag, setFilterFlag] = useState<any>(false);
    // Start Review Blade
    const [showReviewBlade, setShowReviewBlade] = useState(false);
    const handleCloseReviewBlade = () => setShowReviewBlade(false);
    const handleShowReviewBlade = () => setShowReviewBlade(true);
    // View Email Archive Blade
    const [showViewBlade, setShowViewBlade] = useState(false);
    const handleCloseViewBlade = () => setShowViewBlade(false);
    const handleShowViewBlade = () => setShowViewBlade(true);
    // Filter Blade
    const [showFilterBlade, setShowFilterBlade] = useState(false);
    const handleCloseFilterBlade = () => setShowFilterBlade(false);
    const handleShowFilterBlade = () => setShowFilterBlade(true);
    const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [bulkReviewList, setBulkReviewList] = useState<any[]>([]);
    const [showBulkReviewBlade, setShowBulkReviewBlade] = useState(false);
    const handleCloseBulkReviewBlade = () => setShowBulkReviewBlade(false);
    const [keywordFlag, setKeywordFlag] = useState<any>(false);
    const [isActive, setIsActive] = useState(false);
    const [changeInList, setChangeInList] = useState<any>([]);
    const [showSavedFilterBlade, setShowSavedFilterBlade] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        watch,
    } = useForm();
    let watchVariable = watch();

    const toggleViewSidebar = () => {
        setIsActive(true);
    };
    const changeIsActive = (isAct: any, tab?: any) => {
        if (tab) {
            setReviewTab(tab)
        }
        setIsActive(isAct);
    }

    useEffect(() => {
        var data1 = atob(localStorage.getItem('ucd') ?? "");
        if (role === "COMPLIANCE_OFFICER") {
            if ((data1 === "" || data1 === undefined)) {
                navigate("/access-denied");
            } else {
                var json = JSON.parse(data1);
                setAutoMark(json.reviewLogs);
                setComplainceUserLogAccess(json.reviewLogs)
            }
        }
    }, []);

    useEffect(() => {
        if (filterFlag) {
            setFilterFlag(false);
        }
    }, [filterObject]);

    useEffect(() => {
        if (keywordFlag) {
            setKeyword("");
        }
        setKeywordFlag(false);
    }, [keywordFlag]);

    const handleShowBulkReviewBlade = () => {
        if (bulkReviewList.length === 0) {
            toast.error("Please select posts for bulk review");
        } else {
            setShowBulkReviewBlade(true);
        }
    };

    const handleAddNotes = () => {
        if (watchVariable.markedAsView === undefined) {
            setValue("markedAsView", false);
            watchVariable.markedAsView = false;
        }
        let body = {
            idList: bulkReviewList,
            markedAsFlag: !watchVariable.markedAsView ? false : watchVariable.markedAsFlag, //uncommented
            markedAsView: watchVariable.markedAsView,
            escalateAndSend: !watchVariable.markedAsView ? false : watchVariable.escalateAndSend,  //uncommented 
            notes: watchVariable.notes,
            socialType: "BLOOMBERG",
        };

        WebService.addLoader("bloomberg-save-btn");
        return WebService.putAPI({
            action: `social/bulk-review`,
            body: body,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                handleCloseBulkReviewBlade();
                setChangeInList(body);
                reset();
                setBulkReviewList([]);
                setChangedObject(false);
                toast.success(res?.message);
                WebService.removeLoader("bloomberg-save-btn");
            })
            .catch((error: any) => {
                WebService.removeLoader("bloomberg-save-btn");
                handleCloseBulkReviewBlade();
                return error;
            });
    };

    return (
        <>
            <div className="page-contetn page-slack-review">
                <Row className="mb-3 align-items-center filter-row">
                    <Col lg={3} xxl={5}>
                        <PageTitle Title="Bloomberg Review" />
                    </Col>
                    <Col lg={9} xxl={7} className="d-flex justify-content-end gap-2">
                        <InputGroup className="table-search-box">
                            <InputGroup.Text id="basic-addon1"><IoSearchOutline className="icon" /></InputGroup.Text>
                            <Form.Control
                                key={keyword}
                                placeholder="Search for anything"
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === "Enter") {
                                        setKeyword(e.currentTarget.value);
                                        filterObject.keywords = e.currentTarget.value;
                                    }
                                    if (e.currentTarget.value === "" && e.key === "Enter") {
                                        setKeywordFlag(true);
                                    }
                                }}
                                defaultValue={keyword}
                            />
                        </InputGroup>
                        {
                            complainceUserLogAccess && role !== "BASIC_USER" ?
                                <div className="bg-white rounded px-3 d-flex text-nowrap gap-2 font-14 align-items-center">
                                    <span className="mb-1">Auto-mark</span>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="ON"
                                        className="mb-"
                                        checked={automark}
                                        onChange={() => {
                                            setAutoMark(!automark);
                                        }}
                                    />
                                </div> :
                                ""
                        }

                        <Dropdown style={totalCount === 0 || !complainceUserLogAccess ? { position: "relative", marginRight: "12px" } : {}}>
                            <Dropdown.Toggle
                                className="btn-brand-light text-nowrap"
                                id="dropdown-basic"
                            >
                                {" "}
                                <BsFunnel size={18} /> Filter
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleShowFilterBlade}>
                                    Custom Filter
                                </Dropdown.Item>
                                {
                                    role !== "BASIC_USER" ?
                                        <Dropdown.Item onClick={() => setShowSavedFilterBlade(true)}>
                                            Saved Filter
                                        </Dropdown.Item>
                                        : ""
                                }
                            </Dropdown.Menu>
                        </Dropdown>

                        {totalCount > 0 && complainceUserLogAccess && role !== "BASIC_USER" ? (
                            <>
                                <Button
                                    className="btn-brand-1 text-nowrap"
                                    onClick={() => {
                                        setValue("markedAsView", true);
                                        setValue("markedAsViewHead", "Mark as viewed");
                                        handleShowBulkReviewBlade();
                                    }
                                    }
                                >
                                    Bulk Review
                                </Button>
                                {/* <Button
                                    className="btn-brand-1 text-nowrap"
                                    onClick={handleShowReviewBlade}
                                >
                                    Start Review <BsArrowRight size={16} />
                                </Button> */}
                            </>
                        ) : (
                            ""
                        )}
                        {/* {totalCount > 0 && complainceUserLogAccess ? (
                                    <Button className="btn-brand-1 text-nowrap" onClick={handleShowReviewBlade}>Start Review <BsArrowRight size={16} /></Button>
                                ) : ""} */}
                    </Col>
                </Row>
                <div className="collapse-page">
                    <div className="data-container">
                        <BloombergTab
                            viewObject={(data: any) => {
                                setViewObject(data);
                                toggleViewSidebar();
                            }}
                            automark={automark}
                            changedObject={changedObject}
                            changeInList={changeInList}
                            keyWord={keyword}
                            filterObject={filterObject}
                            clearFilterCallback={(type: any, data: any) => {
                                setFilterFlag(true);
                                let obj = { ...filterObject };
                                if (type === "ALL") {
                                    obj = {};
                                    setKeywordFlag(true);
                                    setKeyword("");
                                } else if (type === "Date") {
                                    obj.startDate = undefined;
                                    obj.endDate = undefined;
                                } else if (type === "Percentage") {
                                    obj.percentage = undefined;
                                } else if (type === "Keyword") {
                                    obj.keywords = "";
                                    setKeyword("");
                                } else if (type === "Flagged") {
                                    obj.markedAsFlag = undefined;
                                } else if (type === "Reviewed") {
                                    obj.markedAsView = undefined;
                                } else if (type === "Escalated") {
                                    obj.escalateAndSend = undefined;
                                } else if (type === "NotReviewed") {
                                    obj.flaggedNotReviewed = undefined;
                                } else if (type === "NotYetReviewed") {
                                    obj.notReviewed = undefined;
                                } else if (type === "sampleCount") {
                                    obj.sampleCount = undefined;
                                } else if (type === "userList") {
                                    obj.userList = obj.userList.filter((user: any) => user.id + user.label !== data);
                                } else if (type === "groupList") {
                                    obj.groupList = obj.groupList.filter((user: any) => user.id + user.label !== data);
                                } else {
                                    obj = { ...obj, ...data };
                                }
                                setFilterObject(obj);
                            }}
                            isActive={isActive}
                            reviewTab={reviewTab}
                            changeIsActive={changeIsActive}
                            sendData={(data: any) => {
                                setChangedObject(data);
                            }}
                            totalCount={(count: any) => {
                                setTotalCount(count);
                            }}
                            sendBulkReviewList={(selectedIds: any) => {
                                setBulkReviewList(selectedIds);
                            }}
                            loader={(bool: any) => { }}
                        />
                    </div>
                </div>
            </div>

            {/* Add Blade  */}
            <Offcanvas show={showReviewBlade} onHide={handleCloseReviewBlade} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Start Review</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="form-style">
                    <ReviewAllFilterBlade
                        type={"BLOOMBERG"}
                        reviewId={(reviewId: any) => {
                            handleCloseReviewBlade();
                            navigate("/review/review-all/" + reviewId);
                        }}
                    />
                </Offcanvas.Body>
            </Offcanvas>


            {/* Filter Blade  */}
            <Offcanvas show={showFilterBlade} onHide={handleCloseFilterBlade} placement="end">
                <BloombergFilterBlade
                    sendFilterData={(filterObject: any) => {
                        if (filterObject.keywords) {
                            setKeyword(filterObject.keywords);
                        } else {
                            setKeyword("");
                        }
                        if (JSON.stringify(filterObject) == "{}") {
                            setKeywordFlag(true);
                            setKeyword("");
                        }
                        setFilterObject(filterObject);
                        handleCloseFilterBlade();
                    }}
                    filter={filterObject}
                />
            </Offcanvas>

            <Offcanvas
                show={showBulkReviewBlade}
                onHide={handleCloseBulkReviewBlade}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Bulk Review</Offcanvas.Title>
                </Offcanvas.Header>
                <Form
                    className="w-100 form-style h-100"
                    name="Verify"
                    id="Verify"
                    onSubmit={handleSubmit(handleAddNotes)}
                >
                    <Offcanvas.Body>
                        <div className="px-3">
                            <Dropdown className="dropdown-toggle2">
                                <Dropdown.Toggle
                                    id=""
                                    variant=""
                                    className="bg-success-subtle dropdown-toggle2"
                                >
                                    <span className="font-16" style={{ color: "Black" }}>{watchVariable.markedAsViewHead}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => { setValue("markedAsView", true); setValue("markedAsViewHead", "Mark as viewed"); }}
                                    >
                                        Mark as viewed
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => { setValue("markedAsView", false); setValue("markedAsViewHead", "Mark as unviewed"); }}
                                    >
                                        Mark as unviewed
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <hr className="border-secondary-subtle w-100" />
                        <div className=" form-style">
                            <div className="px-3">
                                <FloatingLabel controlId="Notes" label="Notes">
                                    <Form.Control
                                        {...register("notes")}
                                        as="textarea"
                                        placeholder="Notes"
                                        style={{ height: "100px" }}
                                        value={watchVariable.notes ? watchVariable.notes : ""}
                                    />
                                </FloatingLabel>
                                <div className="mt-3">
                                    <Button
                                        type="submit"
                                        className="btn btn-brand-1 w-100"
                                        id="bloomberg-save-btn"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Form>
            </Offcanvas>

            <Offcanvas
                show={showSavedFilterBlade}
                onHide={() => setShowSavedFilterBlade(false)}
                placement="end"
            >
                <SavedFilterBlade
                    sendData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        setShowSavedFilterBlade(false);
                    }}
                    parentCallback={filterObject}
                    connectorType={"BLOOMBERG"}
                />
            </Offcanvas>
        </>
    )
}
export default BloombergReview;

import { Button, Offcanvas, Spinner, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp, MdOutlineModeEdit
} from "react-icons/md";
import { PiTrash } from "react-icons/pi";
import WebService from "../../Services/WebService";
import TableLoader from "../Common/TableLoader/TableLoader";
import moment from "moment";
import NoDataFound from "../../assets/images/empty_item.svg";
import EmailFilterBlade from "../../components/EmailReview/FilterBlade";
import TeamsFilterBlade from "../../components/TeamsReview/FilterBlade";
import HelperService from "../../Services/HelperService";
import { useSelector } from "react-redux";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import { TooltipCustom } from "../Common/Tooltip/Tooltip";
import SocialFilterBlade from "../SocialReview/FilterBlade";
import WebFilterBlade from "../WebsiteReview/FilterBlade";
import SMSFilterBlade from "../SmsReview/SmsFilterBlade";
import ZoomFilterBlade from "../ZoomReview/FilterBlade";
import SlackFilterBlade from "../SlackReview/FilterBlade";
import BloombergFilterBlade from "../BloombergReview/FilterBlade";


interface propsData {
    sendData: any;
    parentCallback: any;
    connectorType: any;
    websiteId?: any;
    selectedTab?: any;
}


const SavedFilterBlade = (props: propsData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [sortBy, setSortBy] = useState("dateTime");
    const [orderBy, setOrderBy] = useState("DESC");
    const [clickable, setClickable] = useState("dateTime");
    const [loader, setLoader] = useState<any>("");
    const [savedFilterList, setSavedFilterList] = useState<any>([]);
    const [showEmailFilterBlade, setShowEmailFilterBlade] = useState(false);
    const [showTeamsFilterBlade, setShowTeamsFilterBlade] = useState(false);
    const [showSocialFilterBlade, setShowSocialFilterBlade] = useState(false);
    const [showWebsiteFilterBlade, setShowWebsiteFilterBlade] = useState(false);
    const [showZoomChatFilterBlade, setShowZoomChatFilterBlade] = useState(false);
    const [showTextFilterBlade, setShowTextFilterBlade] = useState(false);
    const [showSlackFilterBlade, setShowSlackFilterBlade] = useState(false);
    const [showBloombergFilterBlade, setShowBloombergFilterBlade] = useState(false);
    const [filterObject, setFilterObject] = useState<any>({});
    const [filterId, setFilterId] = useState();
    const [filterName, setFilterName] = useState<any>("");
    const [action, setAction] = useState("");
    const [showDelete, setShowDelete] = useState(false);


    useEffect(() => {
        getSavedFilterList();
    }, []);

    const getSavedFilterList = () => {
        setLoader(true);
        return WebService.postAPI({
            action: `filter/list?page=1&type=${getConnectorType(props.connectorType)}&accountType=${props.connectorType === "XPersonal" ? "PERSONAL" : props.connectorType === "XBusiness" ? "BUSINESS" : ""}`,
            body: null,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                setSavedFilterList(res?.list);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                return error;
            });
    }


    const handleApplyFilter = (obj: any) => {
        if (obj?.startDate) {
            obj.startDate = moment(obj.startDate).format("YYYY-MM-DD");
        }
        if (obj?.endDate) {
            obj.endDate = moment(obj.endDate).format("YYYY-MM-DD");
        }
        if (obj?.userList && obj?.userList?.length > 0) {
            obj.userList = obj?.userList.map((user: any) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
                category: "user"
            }));
        }
        if (obj?.groupList && obj?.groupList?.length > 0) {
            obj.groupList = obj?.groupList.map((group: any) => ({
                value: group.id,
                label: `${group.group_name}`,
                category: "group",
            }))
        }
        if (props.connectorType === "TEAMS" && obj?.accounts && obj?.accounts?.length > 0) {
            obj.accounts2 = obj?.accounts.map((ele: any) => ({
                id: ele.id,
                label: `${ele.name}`,
            }))

            obj.accounts = obj?.accounts.map((ele: any) => ({
                id: ele.id,
            }))
        }
        if (props.connectorType === "WEBSITE" && obj?.pages && obj?.pages?.length > 0) {
            obj.pages = obj?.pages.map((page: any) => ({
                id: page.id,
                label: `${page.websiteUrl}`,
            }))
        }
        if (props.connectorType === "ZOOM_CHAT" && obj?.accounts && obj?.accounts?.length > 0) {
            obj.accounts = obj?.accounts.map((ele: any) => ({
                id: ele.id,
                socialType: ele.socialType,
                label: `${ele.name}`,
            }))
        }
        if ((props.connectorType === "Facebook" ||
            props.connectorType === "InstagramBusiness" ||
            props.connectorType === "LinkedIn" ||
            props.connectorType === "XPersonal" ||
            props.connectorType === "XBusiness" ||
            props.connectorType === "YouTube" ||
            props.connectorType === "Stocktwits"
        )
            && obj?.accounts && obj?.accounts?.length > 0) {
            obj.accounts = obj?.accounts.map((ele: any) => ({
                value: ele.id,
                label: ele.name,
                social_type: ele.socialType,
            }))
        }

        props.sendData(obj, "FILTER");
    }


    const deleteList = (isDelete: any) => {
        if (isDelete) {
            getSavedFilterList();
        }
        setShowDelete(false);
    };

    const [loadingButtonId, setLoadingButtonId] = useState(null);

    const getConnectorType = (channelName: any) => {
        switch (channelName) {
            case "SMS": return "SMS";
            case 'Instagram': return "INSTAGRAM";
            case 'LinkedIn': return "LINKEDIN";
            case 'InstagramPersonal': return "INSTAGRAM";
            case 'InstagramBusiness': return "INSTAGRAM";
            case "ZOOM_CHAT": return "ZOOM_CHAT";
            case 'XPersonal': return "X";
            case 'XBusiness': return "X";
            case "EMAIL": return "EMAIL";
            case "TEAMS": return "TEAMS";
            case "WEBSITE": return "WEBSITE";
            case "RING_CENTRAL": return "RING_CENTRAL";
            case "ZOOM_SMS": return "ZOOM_SMS";
            case 'YouTube': return "YOUTUBE";
            case 'Facebook': return "FACEBOOK";
            case "IMESSAGE": return 'IMESSAGE';
            case "MY_REPCHAT": return "MY_REPCHAT";
            case "SLACK": return "SLACK";
            case "WHATSAPP": return "WHATSAPP";
            case 'Stocktwits': return "STOCKTWITS";
            case "OTHER_SMS": return "OTHER_SMS";
            case "BLOOMBERG": return "BLOOMBERG";
            default: return "";
        }
    };

    const getFilterData = (id: any) => {
        setLoadingButtonId(id);
        return WebService.getAPI({
            action: `filter/detail?id=${id}&type=${getConnectorType(props.connectorType)}`,
            body: null,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                setFilterObject(res);
                if (props.connectorType === "EMAIL") {
                    setShowEmailFilterBlade(true);
                } else if (props.connectorType === "TEAMS") {
                    setFilterObject({
                        ...res,
                        "accounts2": res?.accounts && res?.accounts?.length > 0 ? res?.accounts.map((ele: any) => ({
                            id: ele.id,
                            label: `${ele.name}`,
                        })) : [],
                        "accounts": res?.accounts && res?.accounts?.length > 0 ? res?.accounts.map((ele: any) => ({
                            id: ele.id,
                        })) : [],
                    });
                    setShowTeamsFilterBlade(true);
                } else if (['Facebook', 'Instagram', 'InstagramPersonal', 'InstagramBusiness', 'XPersonal', 'XBusiness', 'LinkedIn', 'YouTube', 'Stocktwits'].includes(props.connectorType)) {
                    setFilterObject({
                        ...res,
                        accounts: res?.accounts && res?.accounts?.length > 0 ? res?.accounts.map((ele: any) => ({
                            value: ele.id,
                            label: ele.name,
                            social_type: ele.socialType,
                        })) : []

                    });
                    setShowSocialFilterBlade(true);
                } else if (props.connectorType === "WEBSITE") {
                    setFilterObject({
                        ...res,
                        "pages": res?.pages && res?.pages?.length > 0 ? res?.pages.map((page: any) => ({
                            id: page.id,
                            label: `${page.websiteUrl}`,
                        })) : [],

                    });
                    setShowWebsiteFilterBlade(true);
                } else if (['SMS', 'RING_CENTRAL', 'ZOOM_SMS', 'IMESSAGE', 'MY_REPCHAT', 'OTHER_SMS', 'WHATSAPP'].includes(props.connectorType)) {
                    setShowTextFilterBlade(true);
                } else if (props.connectorType === "ZOOM_CHAT") {
                    setFilterObject({
                        ...res,
                        "accounts": res?.accounts && res?.accounts?.length > 0 ? res?.accounts.map((account: any) => ({
                            id: account.id,
                            label: `${account.name}`,
                        })) : [],

                    });
                    setShowZoomChatFilterBlade(true);
                } else if (props.connectorType === "SLACK") {
                    setShowSlackFilterBlade(true);
                } else if (props.connectorType === "BLOOMBERG") {
                    setShowBloombergFilterBlade(true);
                }
                setLoadingButtonId(null);
            })
            .catch((error: any) => {
                setLoadingButtonId(null);
                return error;
            });

    }



    return (
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Saved Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-0 py-3">
                <div className="collapse-page">
                    <div className="table-card overflow-hidden w-100" >
                        <div className="w-100">
                            <div className="table-card table-container" style={{ boxShadow: "unset !important" }}>
                                {
                                    !loader
                                        ?
                                        savedFilterList?.length > 0
                                            ?

                                            <Table className="table-style">
                                                <thead>
                                                    <tr>
                                                        <th className="cursor-pointer"
                                                            onClick={() => {
                                                                setSortBy("to_email");
                                                                setClickable("to_email");
                                                            }}
                                                        >
                                                            Filter Name
                                                            {sortBy === "to_email" &&
                                                                orderBy === "DESC" &&
                                                                clickable === "to_email" ? (
                                                                <MdOutlineKeyboardArrowDown
                                                                    className="sort-icon cursor-pointer"
                                                                    size={14}
                                                                    onClick={() => {
                                                                        setOrderBy("ASC");
                                                                        setSortBy("to_email");
                                                                        setClickable("to_email");
                                                                    }}
                                                                />
                                                            ) : clickable === "to_email" ? (
                                                                <MdOutlineKeyboardArrowUp
                                                                    className="sort-icon cursor-pointer"
                                                                    size={14}
                                                                    onClick={() => {
                                                                        setOrderBy("DESC");
                                                                        setSortBy("to_email");
                                                                    }}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </th>
                                                        <th className="action-col cursor-pointer">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        savedFilterList.map((filter: any, index: any) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <TooltipCustom
                                                                            st={
                                                                                <span>
                                                                                    {HelperService.truncate(
                                                                                        HelperService.removeUnderScore(
                                                                                            filter?.name
                                                                                        ),
                                                                                        25
                                                                                    )}
                                                                                </span>
                                                                            }
                                                                            message={filter?.name}
                                                                            position="top"
                                                                            id="c_btn"
                                                                        >
                                                                        </TooltipCustom>
                                                                    </td>
                                                                    <td>
                                                                        <div className="action-btns">
                                                                            <Button
                                                                                className="action-btn btn-view btn-light font-12"
                                                                                title="Apply"
                                                                                onClick={() => {
                                                                                    handleApplyFilter(filter)
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                            <Button
                                                                                id={filter?.id}
                                                                                className="action-btn btn-view "
                                                                                title="Edit"
                                                                                onClick={() => {
                                                                                    setFilterId(filter?.id);
                                                                                    setFilterName(filter?.name);
                                                                                    getFilterData(filter?.id);
                                                                                }}
                                                                            >
                                                                                {loadingButtonId === filter.id ? (
                                                                                    <Spinner size="sm" color="black" style={{ pointerEvents: "none" }} />
                                                                                ) :
                                                                                    <>
                                                                                        <MdOutlineModeEdit className="icon" />
                                                                                        Edit
                                                                                    </>
                                                                                }
                                                                            </Button>

                                                                            <Button
                                                                                className="action-btn btn-delete"
                                                                                title="Delete"
                                                                                onClick={() => {
                                                                                    setAction(`filter/remove?id=${filter?.id}`);
                                                                                    setShowDelete(true);
                                                                                }}
                                                                            >
                                                                                <PiTrash className="icon" />
                                                                            </Button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                            :
                                            <div className="table-wrap position-relative w-100">
                                                <div className="no-data-found w-100">
                                                    <div className="text-center">
                                                        <img
                                                            src={NoDataFound}
                                                            width={110}
                                                            className="mb-3"
                                                            alt={""}
                                                        />
                                                        <h3 className="text-secondary font-12">
                                                            No Saved Filter Found
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                        : <TableLoader />
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </Offcanvas.Body>

            <Offcanvas
                show={showEmailFilterBlade}
                onHide={() => setShowEmailFilterBlade(false)}
                placement="end"
            >
                <EmailFilterBlade
                    sendData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowEmailFilterBlade(false);
                    }}
                    filterId={filterId}
                    parentCallback={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                />
            </Offcanvas>

            <Offcanvas
                show={showTeamsFilterBlade}
                onHide={() => setShowTeamsFilterBlade(false)}
                placement="end"
            >
                <TeamsFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowTeamsFilterBlade(false);
                    }}
                    filterId={filterId}
                    filter={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                />
            </Offcanvas>

            <Offcanvas
                show={showSocialFilterBlade}
                onHide={() => setShowSocialFilterBlade(false)}
                placement="end"
            >
                <SocialFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowSocialFilterBlade(false);
                    }}
                    filterId={filterId}
                    filter={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                    socialType={props.connectorType}
                />
            </Offcanvas>

            <Offcanvas
                show={showWebsiteFilterBlade}
                onHide={() => setShowWebsiteFilterBlade(false)}
                placement="end"
            >
                <WebFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowWebsiteFilterBlade(false);
                    }}
                    filterId={filterId}
                    parentCallback={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                    website_id={props.websiteId}
                />
            </Offcanvas>

            <Offcanvas
                show={showTextFilterBlade}
                onHide={() => setShowTextFilterBlade(false)}
                placement="end"
            >
                <SMSFilterBlade
                    sendData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowTextFilterBlade(false);
                    }}
                    filterId={filterId}
                    parentCallback={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                    selectedTab={props.selectedTab}
                />
            </Offcanvas>

            <Offcanvas
                show={showZoomChatFilterBlade}
                onHide={() => setShowZoomChatFilterBlade(false)}
                placement="end"
            >
                <ZoomFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowZoomChatFilterBlade(false);
                    }}
                    filterId={filterId}
                    filter={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                />
            </Offcanvas>

            <Offcanvas
                show={showSlackFilterBlade}
                onHide={() => setShowSlackFilterBlade(false)}
                placement="end"
            >
                <SlackFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowSlackFilterBlade(false);
                    }}
                    filterId={filterId}
                    filter={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                />
            </Offcanvas>

            <Offcanvas
                show={showBloombergFilterBlade}
                onHide={() => setShowBloombergFilterBlade(false)}
                placement="end"
            >
                <BloombergFilterBlade
                    sendFilterData={(filterObject: any) => {
                        setFilterObject(filterObject);
                        getSavedFilterList();
                        setShowBloombergFilterBlade(false);
                    }}
                    filterId={filterId}
                    filter={filterObject}
                    fromSavedFilter={true}
                    filterName={filterName}
                />
            </Offcanvas>


            <DeleteModal
                action={action}
                show={showDelete}
                onDelete={(deleted: boolean) => deleteList(deleted)}
                type="DELETE"
            />

        </>
    )
}
export default SavedFilterBlade;











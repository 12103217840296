import { Routes, Route, RoutesProps } from "react-router-dom";
import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import TwoFAOtp from "../components/Login/TwoFAOtp";
import MyClients from "../components/Login/MyClients";
import FlaggedEmailPreview from "../components/EmailReview/FlaggedEmailPreview";
import ExportData from "../components/Connector/ExportData";
import MessageReview from "../components/WhatsappReview/WhatsappNew/MessageReview";
import ScheduleReview from "../components/Dashboard/ScheduleReview";
import BloombergReview from "../components/BloombergReview/BloombergReview";



const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));
  const Otp = React.lazy(() => import("../components/Login/Otp"));

  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const Code = React.lazy(
    () => import("../components/Login/code")
  );
  const ResetPass = React.lazy(
    () => import("../components/Login/ResetPass")
  );
  const SuccessSent = React.lazy(() => import("../components/Login/Success"));
  const SetPassword = React.lazy(
    () => import("../components/Login/SetPassword")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const FlagEmailPreview = React.lazy(
    () => import("../components/EmailReview/FlaggedEmailPreview")
  );
  const Connector = React.lazy(
    () => import("../components/Connector/Connector")
  );
  const ResetPassword = React.lazy(
    () => import("../components/Login/SetPassword")
  );
  const DirectLogin = React.lazy(
    () => import("../components/Common/TableLoader/TableLoader")
  );
  const Flagging = React.lazy(() => import("../components/Flagging/Flagging"));


  const Organization = React.lazy(
    () => import("../components/Organization/Organization")
  );
  const AccessDenied = React.lazy(
    () => import("../components/Common/AccessDenied/AccessDenied")
  );
  const EmailReview = React.lazy(
    () => import("../components/EmailReview/EmailReview")
  );
  const EmailBulkReview = React.lazy(
    () => import("../components/EmailReview/EmailBulkReview")
  );
  const SMSBulkReview = React.lazy(
    () => import("../components/SmsReview/SMSBulkReview")
  );
  const Review = React.lazy(
    () => import("../components/SavedReview/ReviewList")
  );
  const ComplianceActivity = React.lazy(
    () => import("../components/Reports/ComplianceActivityReport/ComplianceActivityReportTable")
  );

  const TeamsReview = React.lazy(
    () => import("../components/TeamsReview/TeamsReview")
  );
  const WebsiteReview = React.lazy(
    () => import("../components/WebsiteReview/WebsiteReview")
  );
  const SocialReview = React.lazy(
    () => import("../components/SocialReview/SocialReview")
  );

  const SmsReview = React.lazy(
    () => import("../components/SmsReview/SmsReview")
  );
  const ZoomReview = React.lazy(
    () => import("../components/ZoomReview/ZoomReview")
  );
  const ReviewAll = React.lazy(
    () => import("../components/ReviewAll/ReviewAll")
  );
  const Escalate = React.lazy(() => import("../components/Escalate/Escalate"));
  const Profile = React.lazy(() => import("../components/Profile/Profile"));
  const Setting = React.lazy(() => import("../components/Settings/Settings"));
  const Billing = React.lazy(() => import("../components/Subscription/Billing"));
  const Authentication = React.lazy(
    () => import("../components/Authentication/Authentication")
  );
  const FlaggingReport = React.lazy(
    () =>
      import("../components/Reports/FlaggingReport/ComplianceFlaggingReport")
  );
  const AccountRedirect = React.lazy(
    () => import("../components/Connector/AccountRedirect")
  );
  const AuthRedirect = React.lazy(
    () => import("../components/AuthRedirect/AuthRedirect")
  );
  const RingCentral = React.lazy(
    () => import("../components/Connector/RingCentral")
  );

  const ConnectorNew = React.lazy(
    () => import("../components/Connector/ConnectorNew/Connector")
  );

  const Book = React.lazy(
    () => import("../components/Book/Book")
  );

  const Pay = React.lazy(
    () => import("../components/Subscription/Pay")
  );

  const SlackReview = React.lazy(
    () => import("../components/SlackReview/SlackReview")
  );

  // const WhatsappReview = React.lazy(
  //   () => import("../components/WhatsappReview/WhatsappReview")
  // );

  const EmailVerification = React.lazy(
    () => import("../components/EmailEncryption/EmailVerification")
  );

  const EmailBox = React.lazy(
    () => import("../components/EmailEncryption/EmailBox")
  );

  const Vault = React.lazy(() => import("../components/Vault/Vault"));
  const HistoricalDataList = React.lazy(
    () => import("../components/HistoricalData/HistoricalDataList"));

  const ReportList = React.lazy(
    () => import("../components/AuditLog/Report/ReportList"));

  const ScheduledReview = React.lazy(
    () => import("../components/ScheduledReview/ScheduledReviewList")
  );

  const AdminDashboard = React.lazy(
    () => import("../components/AdminDashboard/AdminDashboard")
  );

  const ConnectorsReport = React.lazy(
    () => import("../components/ConnectorsReport/ConnectorsReport")
  );

  const ComplianceDashboard = React.lazy(
    () => import("../components/ComplianceDashboard/ComplianceDashboard")
  );

  const ComplianceMyClient = React.lazy(
    () => import("../components/ComplianceDashboard/ComplianceMyClient")
  );

  const ComplianceConnectorReport = React.lazy(
    () => import("../components/ComplianceDashboard/ComplianceConnectorReport")
  );

  const ViewClient = React.lazy(
    () => import("../components/ComplianceDashboard/ManageClients/ViewClient")
  );

  const ComplianceContacts = React.lazy(
    () => import("../components/ComplianceDashboard/ComplianceContacts/ComplianceContacts")
  )


  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/auth/redirect/:token"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <AuthRedirect />
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/login/:socialType/:id  "
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />

          <Route
            path="/2FA-authentication"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <TwoFAOtp />{" "}
              </Suspense>
            }
          />

          <Route
            path="/my-clients"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <MyClients />{" "}
              </Suspense>
            }
          />

          <Route
            path="/compliance-overview"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ComplianceDashboard />{" "}
              </Suspense>
            }
          />

          <Route
            path="/compliance-client"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ComplianceMyClient />{" "}
              </Suspense>
            }
          />

          <Route
            path="/compliance-connector-report"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ComplianceConnectorReport />{" "}
              </Suspense>
            }
          />

          <Route
            path="/compliance-contacts"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ComplianceContacts />{" "}
              </Suspense>
            }
          />

          <Route
            path="/manage-clients/view-client/:id"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ViewClient />{" "}
              </Suspense>
            }
          />

          <Route
            path="/otp"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Otp />{" "}
              </Suspense>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />

          <Route
            path="/code"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Code />{" "}
              </Suspense>
            }
          />

          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPass />{" "}
              </Suspense>
            }
          />


          <Route
            path="/reset-password/:token"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/success"
            element={
              <Suspense fallback={<></>}>

                <SuccessSent />{" "}
              </Suspense>
            }
          />
          <Route
            path="/create-password/:token"
            element={
              <Suspense fallback={<></>}>
                <SetPassword />
              </Suspense>
            }
          />
          <Route
            path="/createpassword/:token"
            element={
              <Suspense fallback={<></>}>
                <SetPassword />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >

            <Route
              path="/overview"
              element={
                <Suspense fallback={<></>}>

                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/email/flagged-Email/:id"
              element={
                <Suspense fallback={<></>}>

                  <FlaggedEmailPreview />
                </Suspense>
              }
            />

            <Route
              path="/flagging"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Flagging />{" "}
                </Suspense>
              }
            />

            <Route
              path="/connector"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ConnectorNew />
                  {" "}
                </Suspense>
              }
            />



            <Route
              path="/organization"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Organization />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/email-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EmailReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/email-bulk-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <EmailBulkReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Review />{" "}
                </Suspense>
              }
            />
            <Route
              path="/access-denied"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AccessDenied />
                </Suspense>
              }
            />

            <Route
              path="/review/teams-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <TeamsReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/website-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <WebsiteReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/website-review/:websiteId"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <WebsiteReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/social-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SocialReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/audit-logs/report"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ReportList />{" "}
                </Suspense>
              }
            />


            <Route
              path="/review/sms-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SmsReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/whatsapp-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <MessageReview />{" "}
                </Suspense>
              }
            />

            <Route
              path="/review/sms-bulk-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SMSBulkReview />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/zoom-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ZoomReview />{" "}
                </Suspense>
              }
            />

            <Route
              path="/review/slack-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <SlackReview />{" "}
                </Suspense>
              }
            />

            <Route
              path="/review/review-all"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ReviewAll />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/review-all/:reviewId"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ReviewAll />{" "}
                </Suspense>
              }
            />
            <Route
              path="/review/escalate"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Escalate flagged={false} />{" "}
                </Suspense>
              }
            />


            <Route
              path="/review/flagged/:date"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Escalate flagged={true} />{" "}
                </Suspense>
              }
            />


            <Route
              path="/profile"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Profile />{" "}
                </Suspense>
              }
            />
            <Route
              path="/billing"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Billing />{" "}
                </Suspense>
              }
            />
            <Route
              path="/setting"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Setting />{" "}
                </Suspense>
              }
            />
            <Route
              path="/authentication"
              element={
                <Suspense fallback={<></>}>
                  <Authentication />{" "}
                </Suspense>
              }
            />
            <Route
              path="/reports/flagging-report"
              element={
                <Suspense fallback={<></>}>
                  <FlaggingReport />{" "}
                </Suspense>
              }
            />
            <Route
              path="linkedin/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"LINKEDIN"} />
                </Suspense>
              }
            />
            <Route
              path="twitter/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"TWITTER"} />
                </Suspense>
              }
            />
            <Route
              path="zoom/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"ZOOM"} />
                </Suspense>
              }
            />
            <Route
              path="teams/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"TEAMS"} />
                </Suspense>
              }
            />
            <Route
              path="facebook/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"FACEBOOK"} />
                </Suspense>
              }
            />
            <Route
              path="instagram/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"INSTAGRAM"} />
                </Suspense>
              }
            />
            <Route
              path="ringcentral/callback"
              element={
                <Suspense fallback={<></>}>
                  <RingCentral linkType={"RING_CENTRAL"} />
                </Suspense>
              }
            />
            <Route
              path="reports/compliance-activity"
              element={
                <Suspense fallback={<></>}>
                  <ComplianceActivity />
                </Suspense>
              }
            />
            <Route
              path="export"
              element={
                <Suspense fallback={<></>}>
                  <ExportData />
                </Suspense>
              }
            />

            <Route
              path="connector-new"
              element={
                <Suspense fallback={<></>}>
                  <Connector />
                </Suspense>
              }
            />
            <Route
              path="youtube/auth/callback"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"YOUTUBE"} />
                </Suspense>
              }
            />
            <Route
              path="slack/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"SLACK"} />
                </Suspense>
              }
            />

            <Route
              path="/review/bloomberg-review"
              element={
                <Suspense fallback={<></>}>
                  <BloombergReview />
                </Suspense>
              }
            />



            <Route
              path="book"
              element={
                <Suspense fallback={<></>}>
                  <Book />
                </Suspense>

              } />

            <Route
              path="/vault"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Vault />{" "}
                </Suspense>
              }
            />

            <Route
              path="stocktwits/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"STOCKTWITS"} />
                </Suspense>
              }
            />

            <Route
              path="google/callback"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"GOOGLE_DRIVE"} />
                </Suspense>
              }
            />

            <Route
              path="salesforce/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"SALESFORCE"} />
                </Suspense>
              }
            />

            <Route
              path="/historical-data"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <HistoricalDataList />{" "}
                </Suspense>
              }
            />

            <Route
              path="/scheduled-review"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ScheduledReview />{" "}
                </Suspense>
              }
            />

            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AdminDashboard />{" "}
                </Suspense>
              }
            />

            <Route
              path="/connectors-report"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ConnectorsReport />{" "}
                </Suspense>
              }
            />


          </Route>

          <Route
            path="/pay/:uuid"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Pay />{" "}
              </Suspense>
            }
          />

          <Route
            path="/email/view/:t_id/:uuid"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <EmailVerification />{" "}
              </Suspense>
            }
          />

          <Route
            path="/email/view"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <EmailBox />{" "}
              </Suspense>
            }
          />

          {/* <Route
            index
            element={<Suspense fallback={<></>}> <DefaultPage /> </Suspense>}
          /> */}
        </Routes>
      </div>
    </>
  );
};

export default Navigation;








